.content .table {
  border-collapse: collapse;
  font-size: calc(1.4rem * var(--font-size-modifier));
  width: 100%;

  tr:nth-of-type(even) {
    background-color: rgb(var(--color-lightest-alternative));
  }

  thead th {
    background-color: rgb(var(--color-brand-darker));
    color: rgb(var(--color-background));
    font-weight: 400;
    padding: 10px 20px;
    text-align: left;

    p {
        margin: 0;
        padding: 0;
    }
  }

  tfoot {
    display: none;
  }

  thead {
    @media (--mobile) {
      display: none;
    }
  }

  tr {
    @media (--mobile) {
      display: flex;
      flex-direction: column;
      margin: 5px -5px;
      padding: 5px;
      background: transparent;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  tbody th {
    font-weight: 600;
    text-align: left;
  }

  tbody td,
  tbody th {
    padding: 10px 20px;

    @media (--mobile) {
      display: block;
      width: 100%;
      padding: 5px 0;
      order: 1;
    }

    &:empty {
      @media (--mobile) {
        display: none;
      }
    }

    &::before {
      @media (--mobile) {
        content: attr(data-label) ': ';
        font-weight: 700;
        display: block;
        font-size: 90%;
      }
    }

    p {
        margin: 0;
        padding: 0;
    }
  }

  tbody th {
    @media (--mobile) {
      order: 0;
    }
  }
}

.persons-table {
    .role {
        width: 15%;
    }
    .name {
        width: 30%;
    }
    .contact {
        width: 30%;
    }
    .additional {
        width: 25%;
    }
}
