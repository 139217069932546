.subpage .content .tiles-nav {
  @media (--min-tablet) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }

  li {
    height: 180px;
    display: flex;
    flex: 0 24%;
    background-color: rgba(var(--color-brand), .8);
    margin: 10px 0;
    padding: 0;
    transition: background-color .3s;
    position: relative;

    &::before {
      display: none !important;
    }

    &::after {
      background-color: rgb(var(--color-vivid-dark));
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.7;
      transition: opacity .3s;
    }

    &:hover {
      background-color: rgba(var(--color-brand), .9);

      &::after {
        opacity: 0.8;
      }
    }

    @media (--min-tablet) {
      margin: 5px;
    }

    a {
      font-size: calc(1.8rem * var(--font-size-modifier));
      display: flex;
      align-items: flex-end;
      width: 100%;
      padding: 10px;
      height: 100%;
      color: rgb(var(--color-background));
      border: 0;
      z-index: 1;
    }

    h2 {
      margin: 0;
    }

    .cover {
      background-size: cover;
      opacity: 0.8;
      filter: grayscale(.65);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}