:root {
  --font-family-decor: Merriweather, Times, Times New Roman, serif;
  --font-family-base: Lato, Helvetica, Arial, sans-serif;
  --font-family-headings: Roboto Slab, Lucida Console, Monaco, monospace;
  --font-size: 62.5%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-decor);
  color: rgb(var(--color-darker));
  margin: 2rem 0;
}

h1 {
  font-size: calc(3rem * var(--font-size-modifier));;
  margin: 3rem 0;
}

h2 {
  font-size: calc(2.4rem * var(--font-size-modifier));;
}

h3 {
  font-size: calc(2.1rem * var(--font-size-modifier));;
}

h4 {
  font-size: calc(1.8rem * var(--font-size-modifier));;
  color: rgb(var(--color-brand-lighter-alternative));
}

h5 {
  font-size: calc(1.6rem * var(--font-size-modifier));
  color: rgb(var(--color-brand-lighter-alternative));
}

h6 {
  font-size: calc(1.4rem * var(--font-size-modifier));
  margin-bottom: 2.5rem;
  color: rgb(var(--color-brand-lighter-alternative));
}