.container {
  margin: 0;
  width: 100vw;
  max-width: 1220px;
  padding: 0 20px;

  @media (--min-tablet) {
    margin: 0 auto;
    width: 100%;
  }

  @media (--tablet) {
    padding: 0 25px;
  }
}

.aside {
  width: auto;
  line-height: 1.5;

  @media (--mobile) {
    order: 1;
  }

  @media (--min-tablet) {
    margin-right: 30px;
    width: 270px;
    min-width: 270px;
  }

  p:first-of-type {
    margin-top: 0;
  }
}

main {
  @media (--min-tablet) {
    flex: 1;
  }
}

#content {
  main .content,
  .cover h1,
  .cover .content {
    opacity: 1;
    transition: opacity .1s;
  }

  &.is-loading {
    .cover h1,
    .cover .content,
    main .content {
      opacity: .1;
      transition: opacity .1s;
    }
  }
}