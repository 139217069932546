.pagination {
  margin: 30px auto;

  @media (--mobile) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: inline-block;
    vertical-align: middle;
  }

  a {
    color: rgb(var(--color-brand));
    padding: 0 10px;
    font-size: calc(1.3rem * var(--font-size-modifier));
    font-weight: 700;
    border-bottom: none;
    display: inline-block;
    vertical-align: middle;
    transition: color .2s;

    &:hover {
      color: rgb(var(--color-alert));
    }

    &.is-active {
      background-color: rgb(var(--color-alert));
      color: rgb(var(--color-background));
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .pagination-control {
    border: 1px solid rgb(var(--color-lighter));;
    color: rgb(var(--color-lighter));
    font-size: calc(2.6rem * var(--font-size-modifier));
    font-weight: 300;
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .page-first,
  .page-last {
    text-indent: -.2rem;
    letter-spacing: -1rem;

    @media (--mobile) {
      display: none;
    }
  }
}
