.statement-popup__overlay {
  @extend %overlay-background-visible;

  background: rgba(var(--color-foreground), .8);
  z-index: 600;

  .statement-popup {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 601;
    background: rgb(var(--color-background));
    width: 80%;
    height: auto;
    padding: 30px;

    @media (--min-tablet) {
      width: 50%;
    }

    p {
      font-size: calc(2.1rem * var(--font-size-modifier));
      text-align: center;

      @media (--min-tablet) {
        font-size: calc(2.5rem * var(--font-size-modifier));
      }
    }
  }

  .statement-popup__close {
    @extend %close;

    cursor: pointer;
    background: transparent;
    border: 0;

    &:hover {
      background: transparent;
    }

    &::before,
    &::after {
      background: rgb(var(--color-background));
      cursor: pointer;
    }
  }

  &.closed {
    display: none;
  }
}