.sub-navigation {
  list-style: none;
  padding: 0;
  margin-top: 0;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    font-size: calc(1.6rem * var(--font-size-modifier));
    position: relative;
    padding: 2px 0 5px 15px;

    @media (--min-tablet) {
      font-size: calc(1.7rem * var(--font-size-modifier));
    }

    &::before {
      content: '';
      border: 2px solid rgb(var(--color-alert));
      border-width: 2px 2px 0 0;
      width: 5px;
      height: 5px;
      display: inline-block;
      left: 0;
      top: 10px;
      position: absolute;
      transform: rotate(45deg);
      border-radius: 1px;
      transform-origin: 50% 50%;
    }

    &.is-active > a {
      font-weight: 700;
    }

    ul {
      margin: 0 0 5px 10px;
    }

    &.is-active ul {
      overflow: hidden;
      animation: rolldown 3s 1;
    }

    li {
      font-size: calc(1.6rem * var(--font-size-modifier));
    }

    li::before {
      border-width: 1px 1px 0 0;
    }
  }

  a {
    color: rgb(var(--color-brand));
    border-bottom: 1px solid rgba(var(--color-vivid), 0);
    transition: border-bottom-color 0.3s, color .3s;

    &:hover {
      border-bottom-color: rgb(var(--color-vivid));
    }
  }

  &.with-overview {
    padding-left: 20px;

    @media (--tablet) {
      padding-left: 0;
    }

    .overview {
      padding: 2px 0 10px 0;
      margin-left: -20px;

      &::before {
        display: none;
      }

      a {
        background: rgb(var(--color-alert));
        display: block;
        color: rgb(var(--color-background));
        padding: 10px 20px;

        &:hover {
          border-bottom: 1px solid rgba(var(--color-vivid), 0);
        }
      }
    }
  }
}

@keyframes rolldown {
  from {
    max-height: 0;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  to {
    max-height: 1000px;
  }
}
