@define-mixin colors-high-contrast {
  --color-foreground: 0, 0, 0;
  --color-background: 255, 248, 50;
  --color-darker: 0, 0, 0;
  --color-dark: 0, 0, 0;
  --color-medium: 0, 0, 0;
  --color-light: 0, 0, 0;
  --color-lighter: 0, 0, 0;
  --color-lightest: var(--color-background);
  --color-alert: 0, 0, 0;
  --color-vivid: 0, 0, 0;
  --color-vivid-dark: 0, 0, 0;
  --color-brand: 0, 0, 0;
  --color-brand-darker: 0, 0, 0;
  --color-brand-lighter: 0, 0, 0;
  --color-brand-lightest: var(--color-background);

  --color-light-alternative: 0, 0, 0;
  --color-medium-alternative: 0, 0, 0;
  --color-medium-alternative-2: 0, 0, 0;
  --color-lighter-alternative: 0, 0, 0;
  --color-lighter-alternative-2: 0, 0, 0;
  --color-lighter-alternative-3: 0, 0, 0;
  --color-lightest-alternative: var(--color-background);
  --color-brand-darker-alternative: 0, 0, 0;
  --color-brand-lighter-alternative: 0, 0, 0;
  --color-brand-lightest-alternative: 0, 0, 0;
}

%theme-high-contrast {
  .acc__high-contrast__label {
    .acc__toggle--on {
      opacity: 1;
    }

    .acc__toggle--off {
      opacity: 0;
    }
  }

  .home-hero picture,
  .cover {
    background: rgb(var(--color-foreground));

    img {
      opacity: 0;
    }
  }

  picture {
    filter: contrast(150%);
    border: 2px solid rgb(var(--color-foreground));
  }

  .small-search input[type="search"],
  .button {
    border: 2px solid rgb(var(--color-foreground));
  }

  .button {
    border: 2px solid rgb(var(--color-foreground)) !important;
    background: rgb(var(--color-background));
    color: rgb(var(--color-foreground));
    font-weight: bold;

    &:hover {
      background: rgb(var(--color-foreground));
      color: rgb(var(--color-background));
      box-shadow: 0 0 0 1px rgb(var(--color-background));
    }
  }

  footer.footer .sub-footer .container .logo::before {
    background-image: url('/themes/user/site/am/images/emblem-sd.png');
    filter: grayscale(1) contrast(10);
  }

  .logo picture {
    border: 0;
  }

  .content-header-entry .entry-date .month {
    color: rgb(var(--color-background));
  }

  .home-shortcuts .container {
    background: rgb(var(--color-foreground));
  }

  .navigation-footer {
    background: rgb(var(--color-foreground));
    border-top: 2px solid rgb(var(--color-background));

    nav a {
      color: rgb(var(--color-background));

      &:hover {
        text-decoration: underline;
      }
    }

    nav li:first-child a {
      border: 2px solid rgb(var(--color-background));
    }
  }

  .nav-top__item {
    border-bottom: 1px solid rgb(var(--color-foreground));

    @media (--min-tablet) {
      border-bottom: 0;
    }
  }
  
  .nav-top__nested-menu {
    @media (--min-tablet) {
      border: 5px solid rgb(var(--color-foreground));
      border-top: 0;
    }
  }

  .nav-top .nav-top__nested-item__link {
    transition: none;
  }

  .nav-top .nav-top__nested-item__link:hover {
    background: rgb(var(--color-foreground));
    color: rgb(var(--color-background));
  }

  .home-shortcuts .container > ul h3 {
    text-decoration: underline;
  }
  
  .home-shortcuts .container > ul li li a {
    font-weight: bold;
    padding-left: 0;
   
    &::before {
      display: none;
    }
  }

  .entry-tools li a {
    filter: contrast(199%);
  }

  table td, table th {
    border: 1px solid rgb(var(--color-foreground));
  }
}