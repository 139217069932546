.gallery-full {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;

  > input {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
  }

  input:checked + .images {
    display: none;
  }

  .image-full {
    @extend %overlay-background;

    figure {
      display: flex;
      max-width: 1000px;
      margin: 0 auto;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    picture {
      position: relative;
      z-index: 30;
      background: rgb(var(--color-background));
      border: 1px solid rgb(var(--color-light));
      margin: 0 auto 80px;
      animation: appear .65s 1;

      @media (--min-tablet) {
        display: block;
        width: auto;
      }
    }

    img {
      margin: 0;
      max-width: 90vw;
      max-height: calc(100vh - 200px);
      padding: 10px;
      display: block;
    }

    figcaption {
      position: absolute;
      background: rgb(var(--color-background));
      padding: 60px 20px 20px;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 120px;
      overflow: auto;
      z-index: 20;
    }

    nav {
      position: absolute;
      bottom: 73px;
      width: 100%;
      display: block;
      left: 0;
      background: rgb(var(--color-background));
      box-shadow: 0 0 15px 0 rgb(var(--color-background));
      z-index: 20;

      .count {
        font-size: calc(1.7rem * var(--font-size-modifier));
        line-height: 2.8;
        width: 100%;
        text-align: center;
        font-weight: 300;
        color: rgb(var(--color-medium));
        font-family: var(--font-family-decor);

        .current {
          position: relative;
          top: -4px;
          font-size: 120%;
        }

        .total {
          position: relative;
          top: 4px;
        }
      }

      .previous,
      .next {
        font: normal 0/0 serif;
        position: absolute;
        display: inline-block;
        cursor: pointer;
        right: 0;
        bottom: 0;
        transition: color .2s;
        width: 50%;
        height: 100vh;

        &:hover::before,
        &:hover::after {
          color: rgb(var(--color-vivid));
        }

        &::before {
          content: '>';
          position: absolute;
          color: rgb(var(--color-foreground));
          display: block;
          right: 0;
          font-weight: 300;
          transform: scaleX(.5);
          bottom: 3px;
          font-size: 3.2rem;
          transition: transform .6s;
          width: 40px;
          height: 40px;
          line-height: 37px;

          @media (--min-tablet) {
            font-size: 4rem;
          }
        }
      }

      .previous {
        left: 0;

        &::before {
          transform: scale(-.5, 1);
          left: 3px;
        }
      }
    }
  }

  .close {
    @extend %close;
  }

  input:checked + .image-full {
    @extend %overlay-background-visible;
  }
}

@keyframes appear {
  from {
    opacity: .2;
  }

  to {
    opacity: 1;
  }
}