.navigation-footer {
  background: rgb(var(--color-lightest-alternative));
  position: relative;
  border-top: 5px solid rgb(var(--color-brand));
  margin-top: 20px;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  @media (--min-tablet) {
    margin-top: 30px;
  }

  > .container {
    padding: 0 10px;
  }

  .mobile-menu-open,
  .mobile-menu-close {
    display: block;
    position: absolute;
    top: 13px;
    right: 10px;
    letter-spacing: 1px;
    font-weight: 100;
    background: rgb(var(--color-background));
    font-size: calc(1.1rem * var(--font-size-modifier));
    cursor: pointer;
    border: 0;

    &:hover,
    &:active {
      background: rgb(var(--color-background));
      color: rgb(var(--color-vivid));
    }

    @media (--min-tablet) {
      display: none !important;
    }
  }

  .mobile-menu-close {
    display: none;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    &:checked + .mobile-menu-open {
      display: none;
    }

    &:checked + .mobile-menu-open + nav + .mobile-menu-close {
      display: inline-block;
    }

    &:checked + .mobile-menu-open + nav {
      max-height: 600px;
      transition-duration: 1s;
    }
  }

  nav {
    max-height: 55px;
    overflow: hidden;
    transition: max-height .3s;

    @media (--min-tablet) {
      max-height: none;
      height: auto;
    }

    @media (--min-desktop) {
      height: auto;
      margin-bottom: 0;
    }

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 10px;
      margin-right: 80px;

      @media (--min-tablet) {
        padding: 10px 20px 0 0;
        margin-right: 0;
      }

      @media (--min-desktop) {
        justify-content: space-between;
        flex-wrap: nowrap;
      }
    }

    li {
      white-space: nowrap;
      padding: 0 5px;
      min-width: 75px;

      @media (--min-tablet) {
        width: 30%;
        white-space: normal;
      }

      @media (--min-desktop) {
        width: auto;
        flex-basis: auto;
        max-width: calc(130px * var(--font-size-modifier));
      }

    }

    a {
      display: block;
      margin: 10px 0 10px 15px;
      text-transform: uppercase;
      font-weight: 600;
      border: 0;
      color: rgb(var(--color-medium-alternative));
      font-size: calc(1rem * var(--font-size-modifier));

      @media (--tablet) {
        font-size: calc(1.1rem * var(--font-size-modifier));
        padding-left: 10px;
      }

      @media (--min-desktop) {
        font-size: calc(1.1rem * var(--font-size-modifier));
        text-align: center;
        margin: 10px 0;
      }

      &:hover {
        color: rgb(var(--color-medium));
      }
    }

    li:first-child a {
      font-size: calc(1rem * var(--font-size-modifier));
      color: rgb(var(--color-background));
      background: rgb(var(--color-alert));
      padding: 10px 5px;
      transition: opacity .3s;
      max-width: none;
      margin-left: 0;
      text-align: center;
      min-width: 100px;

      @media (--min-tablet) {
        font-size: calc(1.1rem * var(--font-size-modifier));
        padding: 10px 25px;
        margin: 0;
        text-align: left;
      }

      @media (--min-desktop) {
        padding: 17px 21px;
        font-size: calc(1.3rem * var(--font-size-modifier));
      }

      &:hover {
        opacity: .9;
      }
    }
  }
}