.content-header-unit {
    height: auto;

    @media (--min-tablet) {
        height: calc(250px * var(--font-size-modifier));
    }

    .cover {
        height: auto;
        padding-right: env(safe-area-inset-right);
        padding-left: env(safe-area-inset-left);
        
        @media (--min-tablet) {
            height: calc(250px * var(--font-size-modifier));
        }
    }

    .background-image {
        height: auto;
        
        @media (--min-tablet) {
            height: calc(250px * var(--font-size-modifier));
        }
    }

    h1 {
        margin: 30px 0;
    }
}