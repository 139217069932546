.acc__dark-mode-notification {
  display: none;
}
  
@media (prefers-color-scheme: dark) {

  #acc__dark-mode-invert:not(:checked) ~ .body-wrapper {
    .acc__dark-mode-notification {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      z-index: 300;

      @media (--min-tablet) {
        text-align: right;
        transform: translateX(-50%);
        left: 50%;
        max-width: 1220px;
        width: 100%;
        padding: 10px 160px 0 0;
      }

      .acc__dark-mode-notification__label {
        padding: 5px 15px 5px 5px;
        background: rgb(var(--color-lighter));
        font-size: calc(1.1rem * var(--font-size-modifier));
        cursor: pointer;
        transition: color .2s;
        position: relative;
        z-index: 30;

        @media (--min-tablet) {
          font-size: calc(1.2rem * var(--font-size-modifier));
          background: rgb(var(--color-lightest));
          border: 1px solid rgb(var(--color-medium));
          text-transform: uppercase;
          padding: 5px 20px 5px 10px;
          margin-right: calc(env(safe-area-inset-right) - 15px);
        }

        &::after {
          content: '';
          width: 0;
          height: 0;
          border-top: 4px solid rgb(var(--color-foreground));
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          border-bottom: 0;
          position: absolute;
          top: 50%;
          transform: translate(5px, -2px);
          transition: transform .5s;
        }

        &:hover,
        &:focus {
          color: rgb(var(--color-foreground));
        }
      }

      .acc__dark-mode-notification__description {
        position: absolute;
        top: auto;
        right: 0;
        background: rgb(var(--color-lightest));
        border: 1px solid rgb(var(--color-lighter));
        border-width: 1px 0 1px 1px;
        z-index: 100;
        padding: 0;
        width: 70vw;
        font-size: calc(1.4rem * var(--font-size-modifier));
        color: rgb(var(--color-foreground));
        line-height: 1.5;
        box-shadow: 0 2px 5px rgba(var(--color-background), .5);
        opacity: 0;
        height: 0;
        overflow: hidden;
        pointer-events: none;
        transform: translateY(-10px);
        margin-top: 5px;
        z-index: 20;

        @media (--min-tablet) {
          max-width: 500px;
          min-width: 300px;
          border-width: 1px;
          width: auto;
          right: 160px;
        }

        p {
          margin: 15px 20px;
          padding: 0;
        }

        label {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      #acc__dark-mode-notification:checked + .acc__dark-mode-notification__label {
        color: rgb(var(--color-foreground));

        &::after {
          transform: translate(5px, -2px) rotate(180deg);
        }
      }

      #acc__dark-mode-notification:checked ~ .acc__dark-mode-notification__description {
        height: auto;
        opacity: .95;
        transform: translateY(0);
        transition: opacity .25s, transform .25s;
        pointer-events: auto;
      }
    }
  }
}