.content-header-entry {
  .entry-date {
    font-family: var(--font-family-headings);
    border-bottom: 1px solid rgb(var(--color-light));
    padding-bottom: 5px;
    margin-bottom: 5px;
    line-height: 1;
    width: fit-content;
    margin-top: 20px;

    @media (--min-tablet) {
      min-width: 170px;
      margin-top: 0;
      border-bottom: 0;
      text-align: center;
    }

    span {
      @media (--min-tablet) {
        display: block;
      }
    }

    .day {
      @media (--min-tablet) {
        font-size: calc(6rem * var(--font-size-modifier));
      }
    }

    .month {
      @media (--min-tablet) {
        font-size: calc(2.4rem * var(--font-size-modifier));
        color: rgb(var(--color-lighter));
        margin-bottom: 4px;
      }
      text-transform: uppercase;
    }

    .year {
      @media (--min-tablet) {
        font-size: calc(1.5rem * var(--font-size-modifier));
      }
    }
  }

  .entry-meta {
    @media (--min-tablet) {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      padding-left: 30px;
    }

    h1 {
      margin: 20px 0;
      color: rgb(var(--color-background));

      @media (--min-tablet) {
        margin: 30px 0;
      }
    }
  }

  .entry-author {
    color: var(--color-lighter);
    font-size: calc(1.1rem * var(--font-size-modifier));
    margin: 20px 0 25px;

    span {
      color: var(--color-background);
      text-transform: uppercase;
    }
  }
}
