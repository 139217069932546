.personel-persons {
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;

    .personel-persons-item {
        margin-bottom: 6rem;

        @media (--min-tablet) {
            flex: 1 0 50%;
        }

        @media (--min-desktop) {
            flex: 1 0 33.3%;
        }
    }

    .personel-persons-image { 
        display: block;
        max-width: 100%;
        margin-bottom: 10px;
    }

    .personel-persons-content {
        max-width: 275px;

        @media (--tablet) {
            max-width: 235px;
        }
    }

    .personel-persons-title {
        color: rgb(var(--color-brand-darker));
        display: block;
        margin-top: 0;
        margin-bottom: 1.5rem;

        @media (--min-tablet) {
            min-height: 54px;
        }
    }

    .personel-persons-name {
        background-color: rgb(var(--color-brand-lightest));
        border-top: 5px solid rgb(var(--color-brand-lightest-alternative));
        color: rgb(var(--color-darker));
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 61px;
        font-style: italic;
    }
}
