@import 'logo.pcss';
@import 'small-search.pcss';
@import 'accessibility-panel.pcss';
@import 'language-panel.pcss';
@import 'navigation-top.pcss';

header.main-header {

  > .container {
    display: flex;
    justify-content: space-between;
    height: 83px;
    padding-top: 15px;
    max-width: calc(100% - env(safe-area-inset-left) - env(safe-area-inset-right) + 50px);

    @media (--min-tablet) {
      height: auto;
      padding-top: 0;
    }

    @media (--min-desktop) {
      max-width: 1220px;
    }
  }
}