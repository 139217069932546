.form {
  .form-group {
    display: flex;
    margin-bottom: 1.5rem;

    .control-label {
      width: 30%;
      padding: 5px 0;
    }
  }

  .form-control {
    @extend %form-control;
  }

  select.form-control {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }
}

.freeform-row {
  .freeform-column {
    margin-bottom: 1.5rem;

    @media (--min-tablet) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .freeform-label {
    padding: 5px 0;

    @media (--min-tablet) {
      width: 20%;
    }
  }

  .freeform-input {
    @extend %form-control;

    @media (--min-tablet) {
      flex: 1;
    }
  }

  .freeform-errors {
    @media (--min-tablet) {
      width: 100%;
      text-align: right;
    }

    > li {
      color: rgb(var(--color-alert)) !important;
    }
  }

  button {
    @extend %button;

    cursor: pointer;
    background-color: rgb(var(--color-vivid));
    margin-top: 30px;
    color: rgb(var(--color-background));
  }
}

.freeform-form-has-errors {
  color: rgb(var(--color-alert)) !important;
}

.error-message {
  padding: 0;
  color: rgb(var(--color-alert));

  li {
    padding: 0 !important;
    font-size: 80%;
  }

  li::before {
    display: none !important;
  }
}