.horizon {
  .reserch-area {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
  }

  .reserch-area > li {
    min-width: 50%;

    a.is-active {
      font-weight: 700;
      border-bottom-color: rgb(var(--color-vivid));
    }
  }

  .search-result {
    padding-top: 30px;
  }

  .button {
    background-color: rgb(var(--color-vivid));
    color: rgb(var(--color-background));
  }

  .pagination .active a {
    pointer-events: none;
    color: rgb(var(--color-lighter));
  }

  .is-button-disabled {
    background-color: gray !important;
  }

  #app {
    h4 {
      margin-bottom: 0;
    }

    .button {
      cursor: pointer;
      background-color: rgb(var(--color-vivid));
      margin-top: 30px;
      color: rgb(var(--color-background));
    }

    h4 + ul {
      padding: 0;

      li {
        padding: 0;
        display: inline-block;

        &::before {
          display: none;
        }
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
