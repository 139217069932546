.entry-tools {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 6px;

      &.print a {
        display: block;
        width: 25px;
        height: 25px;
        background-image: var(--image-print);
        background-repeat: no-repeat;
      }

      &.pdf a {
        display: block;
        width: 19px;
        height: 25px;
        background-image: var(--image-pdf);
        background-repeat: no-repeat;
        position: relative;

        display: none;

        &.is-loading::before {
          content: '';
          width: 19px;
          height: 19px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          border-width: 4px;
          border-style: solid;
          border-top-color: rgba(var(--color-vivid), .75);
          border-left-color: rgba(var(--color-vivid), .5);
          border-bottom-color: rgba(var(--color-vivid), .25);
          border-right-color: rgba(var(--color-vivid), .05);
          animation: rotate 1s infinite linear;
        }
      }
    }
  }

  @keyframes rotate {
    from {
      transform: translate(-50%, -50%);
      opacity: 1;
    }

    50% {
      opacity: .25;
    }

    to {
      opacity: 1;
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }