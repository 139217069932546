@import 'navigation-footer.pcss';

footer.footer {
  padding: 0;

  .sub-footer .container {
    color: rgb(var(--color-medium-alternative));
    padding: 0 20px 20px;
    padding-left: calc(20px + env(safe-area-inset-left));
    padding-right: calc(20px + env(safe-area-inset-right));
    text-align: center;

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    > div {
      @media (--min-tablet) {
        width: 25%;
      }
    }

    @media (--min-tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0;
      box-sizing: border-box;
    }

    @media (--over-desktop) {
      padding: 0;
    }

    a[href*="polityka-prywatnosci"],
    a[href*="deklaracja-dostepnosci"] {
      color: rgb(var(--color-vivid));
      border-bottom: 1px solid rgba(255, 255, 255, 0);
      transition: border-bottom-color 0.3s, color 0.3s;
      display: block;
      margin-top: 5px;

      @media (--min-tablet) {
        display: inline-block;
      }

      &:hover {
        border-color: rgb(var(--color-vivid));
      }
    }

    .logo {
      text-align: center;
      order: 2;
      padding: 0 30px;
      transform: none;
      margin: 18px 0;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      @media (--mobile-narrow) {
        padding: 0;
      }

      @media (--min-tablet) {
        flex-grow: 1;
        padding: 0;
        width: 25%;
      }

      &::before {
        content: '';
        background: transparent 50% 0 no-repeat;
        background-size: auto 53px;
        background-image: var(--image-emblem-mono);
        width: 52px;
        height: 52px;
        display: inline-block;
      }

      @media (--mobile-narrow) {
        &::before {
          float: left;
        }
      }

      @media (--min-desktop) {
        &::before {
          background-size: auto 104px;
          margin-top: 25px;
          width: 212px;
          height: 102px;
        }
      }

      span {
        text-transform: uppercase;
        font-weight: 600;
        display: block;
        margin-top: 5px;
        margin-bottom: 30px;
        font-size: calc(1.2rem * var(--font-size-modifier));

        @media (--mobile-narrow) {
          float: left;
          width: 150px;
          margin-top: 10px;
        }

        @media (--min-desktop) {
          font-size: calc(1.4rem * var(--font-size-modifier));
          margin-bottom: 44px;
        }
      }
    }

    .faculty-info {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;
      text-align: center;

      @media (--min-tablet) {
        width: 40%;
        text-align: left;
        justify-content: space-between;
      }

      h4 {
        width: 100%;
        font-family: var(--font-family-base);
        font-weight: 400;
        color: var(--color-foreground);
        margin-top: 0;

        @media (--tablet) {
          font-size: calc(1.6rem * var(--font-size-modifier));
          line-height: 1.2;
          margin-top: 10px;
        }
      }
    }

    .faculties {
      width: 100%;

      @media (--min-tablet) {
        width: 40%;
      }

      .faculties-navigation {
        width: 100%;
        display: flex;
      }

      .faculties-navigation__item {
        width: 20%;
        margin: 0;
        padding: 0;

        @media (--min-tablet) {
          justify-content: flex-end;
          display: flex;
        }
      }

      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .navigation__item__label {
        display: none;
      }

      .faculties-navigation.faculties-navigation.faculties-navigation.faculties-navigation .faculties-navigation__item__link {
        justify-content: center;
      }
    }

    .address {
      font-size: calc(1.3rem * var(--font-size-modifier));
      order: 1;
      float: left;
      text-align: left;
      margin-right: 20px;
      clear: both;

      @media (--mobile-wider) {
        padding-bottom: 20px;
        margin-right: 10px;
        width: 30%
      }

      @media (--min-tablet) {
        float: none;
        padding: 0 15px 0 0;
        margin-right: 0;
        flex-grow: 1;
      }
    }

    .contact {
      order: 1;
      float: right;
      padding-bottom: 20px;

      @media (--mobile-wider) {
        float: left;
        width: 30%
      }

      @media (--min-tablet) {
        margin-top: 10px;
        float: none;
        flex-grow: 1;
        text-align: left;
      }

      .button {
        color: rgb(var(--color-medium-alternative));
        font-size: calc(1rem * var(--font-size-modifier));
        border: 1px solid rgb(var(--color-medium-alternative)) !important;
        padding: 10px;
        white-space: nowrap;
        height: auto;

        @media (--min-desktop) {
          padding: 15px;
          font-size: calc(1.2rem * var(--font-size-modifier));
        }

        &:hover {
          background: rgb(var(--color-vivid));
          border-color: rgb(var(--color-vivid)) !important;
          color: rgb(var(--color-background));
        }
      }
    }

    .bip {
      clear: both;
      order: 3;
      flex-grow: 1;
      float: left;
      margin: 20px 0 10px;

      @media (--mobile-wider) {
        clear: none;
        float: left;
        margin: 0 10px;
        transform: scale3d(.9, .9, 1);
        width: 30%
      }

      @media (--min-tablet) {
        float: none;
        width: 30%;
        margin: 0;
      }

      @media (--min-desktop) {
        width: 35%;
      }

      a {
        position: relative;
        display: block;
        border: 0;
        font: normal 0/0 serif;
        width: 162px;
        height: 45px;
        background: transparent 0 0 no-repeat;
        background-size: 162px 45px;
        background-image: var(--image-bip);
      
      @media (--mobile-wider) {
          margin: 0 0 0 auto;
        }

        @media (--min-tablet) {
          margin: 0 0 0 auto;
        }

        @media (--tablet) {
          transform: scale3d(.85, .85, 1);
        }
      }
    }

    .social {
      position: relative;
      order: 3;
      text-align: left;
      flex-grow: 1;
      float: right;
      top: 15px;

      @media (--mobile-wider) {
        clear: both;
        float: none;
        margin: 0 auto 20px;
        text-align: center;
        top: 0;
      }

      @media (--min-tablet) {
        float: none;
        margin-top: 0;
        top: -9px;
        text-align: right;
        width: 20%;
      }

      @media (--min-desktop) {
        width: 15%;
      }

      a {
        position: relative;
        display: inline-block;
        border: 0;
        font: normal 0/0 serif;
        width: 24px;
        height: 24px;
        background: transparent 0 0 no-repeat;
        background-size: 24px 24px;
        background-image: var(--image-instagram);
      
        @media (--min-tablet) {
          width: 34px;
          height: 34px;
          background-size: 34px 34px;
        }
      }

      a[href*="facebook"] {
        margin-right: 12px;
        background-image: var(--image-facebook);
      }
    }
  }

  .copyright {
    text-align: center;
    box-shadow: 0 1px 0 0 rgb(var(--color-lighter-alternative)) inset;
    color: rgb(var(--color-medium-alternative));
    font-size: calc(1.2rem * var(--font-size-modifier));
    padding: 15px;
    clear: both;

    a {
      color: rgb(var(--color-vivid));
      border-bottom: 1px solid rgba(255, 255, 255, 0);
      transition: border-bottom-color 0.3s, color 0.3s;

      &:hover {
        border-color: rgb(var(--color-vivid));
      }
    }
  }
}