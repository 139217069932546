.gallery {
  line-height: 1;
  display: flex;
  flex-direction: column;

  .gallery__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: center;
    position: relative;
    padding: 0;
    transition: background-color .5s;
    line-height: 1;
    overflow: hidden;
    transition: box-shadow .5s;
    background: rgb(var(--color-medium));
    color: rgb(var(--color-background));
    width: 100%;
    margin: 0;
    border: 2px solid rgb(var(--color-background));
    box-shadow: 0 0 0 1px rgba(var(--color-lighter), 1);

    &:hover {
      box-shadow: 0 0 0 5px rgba(var(--color-vivid), .5);
    }

    .gallery__item__wrapper {
      width: 100%;
      max-height: 240px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (--tablet) {
        max-height: 320px;
      }

      @media (--min-desktop) {
        max-height: 430px;
      }
    }

    .gallery__item__wrapper__image {
      box-shadow: 0 0 14px rgba(var(--color-foreground), .25);
      transition: box-shadow .5s;
      display: block;
      width: 100%;
      height: auto;
      margin: 0 auto;
    }

    .gallery__item__caption {
      font-size: calc(1.1rem * var(--font-size-modifier));
      line-height: 1.3;
      text-align: left;
      padding: 10px;

      &:empty {
        display: none;
      }
    }

    .gallery__item__trigger {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      font: normal 0/0 serif;
      color: transparent;

      &:focus ~ .gallery__item__wrapper .gallery__item__wrapper__image {
        box-shadow: 0 0 0 5px rgba(var(--color-vivid), .3);
      }
    }
  }

  .gallery__trigger {
    position: relative;
    width: 100%;
    margin: 20px 0;
    text-align: center;

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background: rgb(var(--color-lighter));
      position: absolute;
      top: 50%;
      left: 0;
    }

    .gallery__trigger__button {
      cursor: pointer;
      border: 1px solid rgb(var(--color-lighter));
      background: rgb(var(--color-background));
      position: relative;
      z-index: 10;
      display: inline-block;
      height: 46px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 46px;
      padding: 0 30px;
      font-size: calc(1.2rem * var(--font-size-modifier));
      transition: background-color .3s, border-color .3s, color .25s;

      &:hover,
      &:focus {
        background: rgb(var(--color-vivid));
        border-color: rgb(var(--color-vivid));
        color: rgb(var(--color-background));
      }
    }
  }

  &.gallery__one-of-many {
    width: 49%;
    height: 49%;
    margin: 0 0 2vw 0;

    &:hover {
      transform: scale3d(1, 1, 1);
    }

    @media (min-width: 460px) and (max-width: 660px) {
      width: 32%;
      height: 32%;
    }

    @media (min-width: 661px) and (max-width: 1024px) {
      width: 24%;
      height: 24%;
    }

    @media (--min-desktop) {
      width: 146px;
      height: 146px;
      margin-bottom: 5px;
    }

    .gallery__item {
      border-width: 1px;
    }

    .gallery__item:hover {
      box-shadow: 0 0 0 3px rgba(var(--color-vivid), .5);
    }
  }

  &.gallery__dummy {
    display: block;
    width: 49%;
    height: 1px;
    margin: 0;

    @media (min-width: 460px) and (max-width: 660px) {
      width: 32%;
    }

    @media (min-width: 661px) and (max-width: 1024px) {
      width: 24%;
    }

    @media (--min-desktop) {
      width: 146px;
    }
  }
}