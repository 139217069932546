.breadcrumbs {
  margin: 15px 0;
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);

  @media (--min-tablet) {
    margin: 30px 0;
  }

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    font-size: calc(1.4rem * var(--font-size-modifier));
    color: rgb(var(--color-medium-alternative));
    padding: 0;
  }

  li {
    margin-right: 5px;
    padding: 0;

    &::before {
      content: '/ ';
    }

    &:first-child::before {
      display: none;
    }

    &.last {
      display: none;
    }
  }

  a {
    color: rgb(var(--color-brand));
    font-weight: 400;

    &:hover {
      border-bottom-color: rgb(var(--color-brand));
      text-decoration: underline;
    }
  }
}