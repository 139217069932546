#acc__high-contrast:not(:checked) ~ #acc__dark-mode:checked ~ .body-wrapper {
  @mixin colors-dark;
  @extend %theme-dark;
}

#acc__dark-mode:checked ~ .body-wrapper {
  .acc__dark-mode__label {
    .acc__toggle--on {
      opacity: 1;
    }

    .acc__toggle--off {
      opacity: 0;
    }
  }
}

#acc__dark-mode-invert:checked ~ .body-wrapper {
  @mixin colors-default;

  .acc__dark-mode-invert__label {
    .acc__toggle--on {
      opacity: 0;
    }

    .acc__toggle--off {
      opacity: 1;
    }
  }
}

@media (prefers-color-scheme: dark) {
  #acc__dark-mode-invert:not(:checked) ~ .body-wrapper {
    @extend %theme-dark;
  }
}