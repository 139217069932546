#acc__colors:checked ~ .body-wrapper {
  filter: grayscale(1);

  .acc__colors__label {
    .acc__toggle--off {
      opacity: 1;
    }

    .acc__toggle--on {
      opacity: 0;
    }
  }
}