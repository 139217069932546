%overlay-background {
  padding: 20px;
  margin: 0 auto;
  display: none;
  background: transparent;
  background-image: linear-gradient(to top, 
    rgba(var(--color-background), 1),
    rgba(var(--color-background), 1) 150px,
    rgba(var(--color-background), .8) 250px,
    rgba(var(--color-background), .8));
  backdrop-filter: blur(5px);
  z-index: 750;
}

%overlay-background-visible {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
}