.nav-top { /* shortened navigation-top because of BEM */
  border-top: 1px solid rgb(var(--color-lighter-alternative));

  .nav-top__container {
    max-width: 1080px;
    background: rgb(var(--color-lightest-alternative));
    border-bottom: 2px solid rgb(var(--color-brand));

    @media (--min-desktop) {
      background: transparent;
      border-bottom: 0;
      padding-left: calc(env(safe-area-inset-left) / 2 + 25px);
      padding-right: calc(env(safe-area-inset-right) / 2 + 25px);
    }
  }

  .nav-top__label {
    display: inline-block;
    position: relative;
    padding: 10px 15px 10px 21px;
    text-transform: uppercase;
    font-size: calc(1.4rem * var(--font-size-modifier));
    letter-spacing: 1px;
    border: 0;
    color: rgb(var(--color-medium));
    font-weight: 400;

    &::before,
    &::after {
      content: '';
      width: 15px;
      height: 2px;
      position: absolute;
      background: rgb(var(--color-vivid));
      top: 15px;
      left: 0;
      transition: transform .2s, top .3s;
    }

    &::after {
      top: 21px;
    }

    @media (--min-desktop) {
      display: none;
    }

    .nav-top__trigger:checked + .body-wrapper & {
      &::after {
        transform: rotate(45deg);
        top: 19px;
      }

      &::before {
        transform: rotate(-45deg);
        top: 19px;
      }
    }
  }

  .nav-top__label-close-all {
    position: absolute;
    background: transparent;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 0;
    opacity: 0.01;
    -webkit-tap-highlight-color: transparent;
  }

  .nav-top__wrapper {
    display: block;
    position: absolute;
    z-index: 200;
    background: rgb(var(--color-background));
    width: 100vw;
    overflow: hidden;
    height: calc(100vh - 123px);
    top: 123px;
    left: 0;
    opacity: .5;
    transform: translateX(-120vw);
    transition: transform .3s cubic-bezier(.6, .01, .68, .95), opacity .2s;
    pointer-events: none;
    animation: do-not-animate .5s 1;

    @media (--mobile) {
      overflow: auto;
    }

    @media (--min-tablet) {
      top: 150px;
    }

    @media (--min-desktop) {
      display: block;
      box-shadow: none;
      position: relative;
      top: 0;
      left: auto;
      width: 100%;
      height: auto;
      overflow: visible;
      transform: none;
      pointer-events: auto;
      transition: none;
      animation: none;
      opacity: 1;
    }

    .nav-top__trigger:checked + .body-wrapper & {
      display: block;
      left: 0;
      transform: translateX(0);
      pointer-events: auto;
      opacity: 1;
    }
  }

  .nav-top__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    width: 100%;
    height: calc(100vh - 223px);
    overflow: auto;
    background-color: rgb(var(--color-brand-lightest));
    padding-bottom: 200px;

    @media (--min-tablet) {
      height: 100vh;
    }

    @media (--min-desktop) {
      display: flex;
      height: auto;
      padding-bottom: 0;
      background-color: rgb(var(--color-background));
      justify-content: space-around;
    }

    &::after {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 5px;
      pointer-events: none;
      background: linear-gradient(to bottom, rgba(var(--color-medium), .25), rgba(var(--color-medium), 0));

      @media (--min-desktop) {
        display: none;
      }
    }
  }

  .acc__panel__popup {

  }

  .nav-top__item {
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 1 1;
    border-bottom: 1px solid rgb(var(--color-background));

    @media (--min-desktop) {
      border-bottom: 0;
    }

    &:first-child .nav-top__item__link[href*="rekrutacja"],
    &:first-child .nav-top__item__link[href*="recruitment"] {
      background-color: rgb(var(--color-alert));
      color: rgb(var(--color-background));
      font-weight: 600;
      padding: 0 20px;
      margin: 0;
      display: block;
      position: relative;
      z-index: 100;

      @media (--min-desktop) {
        margin: 6px 0 0;
        padding: 0 10px;
        height: 41px;
        line-height: 41px;
      }
    }
  }

  .nav-top__item__link,
  .nav-top__item__label {
    display: block;
    position: relative;
    width: 100%;
    height: 43px;
    line-height: 43px;
    border: 0;
    color: rgb(var(--color-brand-lighter));
    font-size: calc(1.8rem * var(--font-size-modifier));
    font-weight: 500;
    padding-left: 20px;
    box-sizing: border-box;
    transition: background-color .2s, color .3s;
    background-color: rgb(var(--color-brand-lightest));
    cursor: pointer;
    white-space: nowrap;
    z-index: 20;
    -webkit-tap-highlight-color: transparent;
    outline: none;

    &:focus {
      outline: 1px solid rgba(var(--color-vivid), .5);
      outline-offset: -10px;
    }

    @media (--min-desktop) {
      text-align: center;
      transition: opacity .25s;
      height: 53px;
      line-height: 53px;
      color: rgb(var(--color-darker));
      background-color: rgb(var(--color-background));
      padding: 0 20px;
      font-size: calc(1.7rem * var(--font-size-modifier));
    }
  }

  .nav-top__item__label {

    &::after {
      content: '';
      border: 2px solid rgb(var(--color-alert));
      border-width: 0 2px 2px 0;
      width: 5px;
      height: 5px;
      display: inline-block;
      left: 5px;
      top: -4px;
      position: relative;
      transform: rotate(45deg);
      border-radius: 1px;
      transition: transform .6s;
      transform-origin: 75% 75%;

      @media (--min-desktop) {
        left: 2px;
        top: -3px;
        transition: transform .6s, top .3s;
      }
    }
  }

  .nav-top__nested-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    width: 100%;
    background: rgb(var(--color-background));
    z-index: 100;
    transform: translateY(-10px);
    opacity: 0;
    box-shadow: -4px 0 4px rgba(0, 0, 0, .05);
    transition: opacity .25s .25s, transform .25s .25s, max-height .5s;
    pointer-events: none;
    max-height: 0;
    overflow: hidden;

    @media (--min-desktop) {
      display: flex;
      position: absolute;
      left: auto;
      top: auto;
      right: auto;
      min-width: 218px;
      max-width: 313px;
      min-height: 0;
      background: rgb(var(--color-background));
      border-bottom: 4px solid rgb(var(--color-alert));
      box-shadow: 0 4px 4px rgba(0, 0, 0, .5);
      transform: translateY(-5px);
      transition: opacity .005s, transform .25s;
    }
  }

  .nav-top__nested-item {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    display: none;
    background: transparent;

    &:last-child .nav-top__nested-item__link {
      @media (--min-desktop) {
        padding-bottom: 13px;
      }
    }
  }

  .faculties-navigation__item {
    display: none;
  }

  .nav-top__nested-item__link {
    position: relative;
    transition: background-color .5s, color .3s;
    padding-left: 35px;
    font-size: calc(1.6rem * var(--font-size-modifier));
    height: 30px;
    line-height: 30px;
    color: rgb(var(--color-brand-lighter));
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;

    &:focus {
      outline: 1px solid rgba(var(--color-vivid), .5);
      outline-offset: -5px;
    }

    @media (--min-desktop) {
      color: var(--color-darkest);
      padding: 10px 28px;
      font-weight: 400;
      line-height: 1.3;
      box-sizing: border-box;
      font-size: calc(1.3rem * var(--font-size-modifier));
      height: auto;
      min-height: 43px;
      text-align: left;
      display: flex;
      align-items: center;
      white-space: normal;
    }

    @media (--short) {
      padding: 5px 28px;
      line-height: 1.2;
      min-height: 30px;
    }

    @media (--min-desktop) {
      font-size: calc(1.6rem * var(--font-size-modifier));
    }

    &:hover {
      @media (--min-desktop) {
        background: rgb(var(--color-lightest-alternative));
        color: rgb(var(--color-foreground));
      }

      &::before {
        @media (--min-desktop) {
          opacity: 1;
          transform: scale3d(1, 1, 1);
          transition: opacity .2s, transform .5s;
        }
      }
    }

    &::before {
      content: '';
      width: 4px;
      height: 100%;
      background: rgb(var(--color-alert));
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      opacity: 0;
      transform: scale3d(.25, 1, 1);
      transform-origin: 0 0;
      transition: opacity 1s, transform .5s;
    }
  }

  .nav-top__item:last-child .nav-top__nested-menu {
    @media (--min-desktop) {
      right: -20px;
    }
  }

  .nav-top__item__trigger:checked + .nav-top__item__label {
    z-index: 0;

    &::after {
      transform: rotate(-135deg);
    }

    + .nav-top__nested-menu {
      overflow: visible !important;
      display: block;
      opacity: 1;
      transform: none;
      pointer-events: auto;
      transition: opacity .3s, transform .4s, max-height 1s;
      max-height: 1400px;

      @media (--mobile-and-tablet) {
        position: relative;
        z-index: 30;
      }

      @media (--min-desktop) {
        transition: opacity .3s, transform .4s;
        max-height: 100vh;
      }

      .nav-top__nested-item,
      .nav-top__nested-item--alt {
        display: block;
      }
    }
  }

  .nav-top__item__trigger:checked + .nav-top__item__label + .nav-top__nested-menu {
    overflow: visible !important;
  
    &.faculties-navigation {
      @media (--min-desktop) {
        display: flex;
      }

      .faculties-navigation__item {
        display: block !important;
      }
    }
  }
}

.nav-top__trigger:checked + .body-wrapper {
  position: fixed;
  height: 100vh;
  z-index: 20;

  @media (--min-desktop) {
    position: static;
    height: auto;
  }
}

@keyframes do-not-animate {
  from {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}