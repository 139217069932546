@define-mixin colors-high-contrast-invert {
  --color-foreground: 255, 248, 50;
  --color-background: 0, 0, 0;
  --color-darker: 255, 248, 50;
  --color-dark: 255, 248, 50;
  --color-medium: 255, 248, 50;
  --color-light: 255, 248, 50;
  --color-lighter: 255, 248, 50;
  --color-lightest: var(--color-foreground);
  --color-alert: 255, 248, 50;
  --color-vivid: 255, 248, 50;
  --color-vivid-dark: 255, 248, 50;
  --color-brand: 255, 248, 50;
  --color-brand-darker: 255, 248, 50;
  --color-brand-lighter: 255, 248, 50;
  --color-brand-lightest: var(--color-foreground);

  --color-light-alternative: 255, 248, 50;
  --color-medium-alternative: 255, 248, 50;
  --color-medium-alternative-2: 255, 248, 50;
  --color-lighter-alternative: 255, 248, 50;
  --color-lighter-alternative-2: 255, 248, 50;
  --color-lighter-alternative-3: 255, 248, 50;
  --color-lightest-alternative: var(--color-foreground);
  --color-brand-darker-alternative: 255, 248, 50;
  --color-brand-lighter-alternative: 255, 248, 50;
  --color-brand-lightest-alternative: 255, 248, 50;
}

%theme-high-contrast-dark {
  .nav-top__item {
    border-bottom: 1px solid rgb(var(--color-background));

    @media (--min-tablet) {
      border-bottom: 0;
    }
  }

  .nav-top .nav-top__item__link,
  .nav-top .nav-top__item__label,
  .nav-top__label,
  .language-panel .language-panel__container a,
  .acc__panel__trigger__label,
  .accordion .slide header label,
  .nav-top .nav-top__item:first-child .nav-top__item__link[href*="rekrutacja"],
  .nav-top .nav-top__item:first-child .nav-top__item__link[href*="recruitment"],
  .file-list .file-name,
  .file-list .file-size {
    color: rgb(var(--color-background));
  }

  .accordion .slide header label {
    background-color: rgb(var(--color-foreground));
  }

  .nav-top .nav-top__item__link,
  .nav-top .nav-top__item__label {
    @media (--min-tablet) {
      color: rgb(var(--color-foreground));
    }
  }

  .nav-top .nav-top__label::before,
  .nav-top .nav-top__label::after,
  .small-search .close::after,
  .file-list .file-icon {
    background-color: rgb(var(--color-background));
  }

  .file-list .file-icon::before,
  .file-list .file-icon::after {
    display: none;
  }

  .small-search .close::before {
    border-color: rgb(var(--color-background));
  }

  .small-search input:focus + button + .close::after,
  .small-search input:focus + button + .close::before {
    background-color: rgb(var(--color-foreground));
  }

  table td,
  table th {
    border: 1px solid rgb(var(--color-foreground));
    background: rgb(var(--color-background));
  }

  table thead th,
  table tfoot th {
    background: rgb(var(--color-foreground));
    color: rgb(var(--color-background));
  }
}