.gallery-list {

  .gallery__on-list {
    position: relative;
    flex-direction: row;
    align-items: stretch;
  
    &::before,
    &::after {
      content: '';
      width: 100%;
      height: auto;
      background: rgb(var(--color-brand-lighter));
      box-shadow: 0 0 0 1px rgb(var(--color-brand-lighter));
      border: 1px solid rgb(var(--color-background));
      display: block;
      top: 0;
      left: 0;
      z-index: 20;
      transform: translate(3px, 1px) rotate(-1deg);
      transition: transform 1s;
      filter: brightness(1.25);
    }

    &::after {
      background: rgba(var(--color-brand-lighter), .5);
      transform: translate(5px, 3px) rotate(-2deg);
      z-index: 10;
    }

    &:hover {
      &::before {
        transform: translate(2px, 4px) rotate(-2deg);
      }

      &::after {
        transform: translate(8px, 8px) rotate(-3deg);
      }
    }
  }

  .gallery__item {
    position: relative;
    display: block;
    z-index: 30;
    margin: 0 -100%;
  }
}

.gallery_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}