 .carousel {
    position: relative;
    width: calc(100% - 2px);
    margin: 0 1px;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    border: 1px solid rgb(var(--color-background));
    border-width: 1px 2px 0 0;

    @media (--min-tablet) {
      width: 100%;
      margin: 0;
      height: calc(100% - 1px);
      flex: 1 1 100%;
      overflow: hidden;
      border: 1px 0 1px 1px;
    }

    .carousel__slide-checkbox:checked + .carousel__slide {
      @media (--min-tablet) {
        opacity: 1;
        position: relative;
        animation: carousel__slide-appear .5s 1 cubic-bezier(.6, .01, .68, .95);
        z-index: 20;
      }

      .carousel__navigation__next,
      .carousel__navigation__previous {
        @media (--min-tablet) {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }

    .carousel__slide-checkbox[id*="-1"]:checked ~ nav .carousel__pagination__trigger[for*="-1"] {
      opacity: 1;
    }

    .carousel__slide-checkbox[id*="-2"]:checked ~ nav .carousel__pagination__trigger[for*="-2"] {
      opacity: 1;
    }

    .carousel__slide-checkbox[id*="-3"]:checked ~ nav .carousel__pagination__trigger[for*="-3"] {
      opacity: 1;
    }

    .carousel__slide-checkbox[id*="-4"]:checked ~ nav .carousel__pagination__trigger[for*="-4"] {
      opacity: 1;
    }

    .carousel__slide {
      position: relative;
      scroll-snap-align: start;
      width: 100%;
      height: 100vw;
      overflow: hidden;
      flex: 0 0 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: rgb(var(--color-medium));
      border-right: 1px solid rgb(var(--color-background));

      &:nth-child(2):nth-last-child(2) {
        animation: none;

        .carousel__navigation {
          display: none;
        }
      }

      @media (--min-tablet) {
        opacity: 0;
        position: absolute;
        animation: carousel__slide-disappear .5s 1 cubic-bezier(.6, .01, .68, .95);
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        flex: 0 0 100%;
      }
    }

    .carousel__slide-content {
      position: absolute;
      max-width: 80%;
      text-align: center;
      padding-left: env(safe-area-inset-left);
    }

    .carousel__slide-text {
      font-size: calc(1.6rem * var(--font-size-modifier));
      text-shadow: 0 1px 0 rgba(0, 0, 0, .5);
      font-weight: 400;
      margin-bottom: 6px;
      color: rgb(var(--color-background));
      display: block;

      @media (--mobile-wide) {
        font-size: calc(2rem * var(--font-size-modifier));
      }

      @media (--mobile-wider) {
        font-size: calc(2.6rem * var(--font-size-modifier));
      }

      @media (--tablet-narrow) {
        font-size: calc(1.8rem * var(--font-size-modifier));
      }

      @media (--min-tablet-wide) {
        font-size: calc(2rem * var(--font-size-modifier));
      }

      @media (--over-desktop) {
        font-size: calc(3rem * var(--font-size-modifier));
      }
    }

    .carousel__slide-header {
      font-size: calc(2.2rem * var(--font-size-modifier));
      line-height: 1.25;
      padding: 0;
      color: rgb(var(--color-background));
      display: block;
      position: relative;

      @media (--mobile-wide) {
        font-size: calc(2.8rem * var(--font-size-modifier));
      }
      
      @media (--mobile-wider) {
        font-size: calc(3.8rem * var(--font-size-modifier));
      }

      @media (--tablet) {
        font-size: calc(2.6rem * var(--font-size-modifier));
      }

      @media (--min-dekstop) {
        font-size: calc(3rem * var(--font-size-modifier));
      }

      @media (--over-desktop) {
        font-size: calc(5.6rem * var(--font-size-modifier));
      }

      &::before {
        content: attr(data-text);
        background: rgba(var(--color-brand), 1);
        color: rgba(var(--color-brand), 0);
        display: inline;
        padding: 0;
        opacity: .55;
        box-shadow: -.5rem 2px 0 3px rgb(var(--color-brand)), .5rem 2px 0 3px rgb(var(--color-brand));
        pointer-events: none;
      }

      .carousel__slide-header-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
      }
    }

    .carousel__slide-button {
      margin: 23px 5px 0;
      color: rgb(var(--color-background));
      font-weight: inherit;
      background: rgb(var(--color-alert));
      border: 2px solid transparent !important;
      transition: border .3s;

      &:hover {
        border: 2px solid rgb(var(--color-background)) !important;
      }
    }

    .carousel__slide-picture {
      @media (--min-tablet) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }

      @media (--min-desktop) {
        right: 0;
      }
    }

    .carousel__slide-image {
      width: auto;
      height: 100vw;
      display: block;

      @media (--min-tablet) {
        width: 100%;
        height: auto;
        min-height: 100%;
        min-width: 100%;
      }

      @media (--tablet-narrow) {
        width: 120%;
        height: auto;
        margin-left: -10%;
      }

      @media (--over-desktop) {
        width: auto;
      }
    }

    .carousel__pagination {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 30px;
      z-index: 50;
      margin-left: env(safe-area-inset-left);

      @media (--mobile) {
        display: none;
      }

      @media (--tablet-narrow) {
        bottom: 20px;
      }

      @media (--min-desktop) {
        bottom: 30px;
      }
      
      .carousel__pagination__list,
      .carousel__pagination__item {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      .carousel__pagination__item {
        display: inline-block;
        width: 13px;
        margin-right: 5px;

        &:first-child:last-child {
          display: none;
        }

        .carousel__pagination__trigger {
          display: block;
          font: normal 0/0 serif;
          width: 13px;
          height: 13px;
          background: rgb(var(--color-background));
          opacity: .5;
          border-radius: 50%;
          transition: opacity .3s;
          border: 0;
          padding: 0;
          cursor: pointer;

          &:hover {
            opacity: .7;
          }

          &.is-active {
            opacity: 1;
          }
        }
      }
    }

    .carousel__navigation {
      position: absolute;
      width: 100%;

      @media (--mobile) {
        display: none;
      }
    }

    .carousel__navigation__next,
    .carousel__navigation__previous {
      opacity: .5;
      transform: translate3d(40px, 0, 0);
      transition: opacity 1s, transform 1s;

      .carousel__navigation__label {
        position: absolute;
        top: 50%;
        right: 20px;
        font: normal 0/0 serif;
        border: 0;
        width: 20px;
        cursor: pointer;

        &:hover::after {
          opacity: 1;
        }

        &::after {
          content: '';
          width: 47px;
          height: 47px;
          background: transparent;
          display: block;
          transform: rotate(45deg) scale(.5, .5);
          border: 2px solid rgb(var(--color-background));
          border-width: 6px 6px 0 0;
          position: absolute;
          top: -7px;
          right: -9px;
          opacity: .5;
          border-radius: 5px;
          transition: opacity .3s;
        }
      }
    }

    .carousel__navigation__previous {
      margin-left: env(safe-area-inset-left);
    }

    .carousel__navigation__previous + .carousel__navigation__last,
    .carousel__navigation__next + .carousel__navigation__first {
      display: none;
    }

    .carousel__navigation__previous .carousel__navigation__label {
      left: 20px;

      &::after {
        transform: rotate(-135deg) scale(.5, .5);
        left: -9px;
      }
    }
}

@keyframes carousel__slide-appear {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes carousel__slide-disappear {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(-100%, 0, 0);;
    opacity: .5;
  }
}