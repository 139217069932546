.modal {
    @extend %overlay-background;
    @extend %overlay-background-visible;
}

.modal-content {
  background-color: rgb(var(--color-background));
  max-width: 600px;
  margin: 60px auto;
  padding: 30px;
  border: 1px solid rgb(var(--color-light));
  position: relative;
}

.close {
    @extend %close;
} 