.acc .acc__panel__item.acc__dark-mode-controls {
  @media (prefers-color-scheme: dark) {
    display: none;
  }
}

.acc .acc__panel__item.acc__dark-mode-invert-controls {
  display: none;

  @media (prefers-color-scheme: dark) {
    display: flex;
  }
}