.home-shortcuts {
  background: rgb(var(--color-brand));
  margin-bottom: -30px;

  .container {
    background: rgb(var(--color-brand)) url('/themes/user/site/am/images/shortcuts-background.jpg') 50% 0 no-repeat;
    background-size: auto 400px;
    position: relative;
    max-width: 1920px;
    width: 100%;
    padding-left: calc(20px + env(safe-area-inset-left));
    padding-right: calc(20px + env(safe-area-inset-right));

    @media (--min-tablet) {
      background-size: cover;
      padding: 0 30px;
      padding-left: calc(30px + env(safe-area-inset-left));
      padding-right: calc(30px + env(safe-area-inset-left));
    }

    @media (--over-desktop) {
      &::before,
      &::after {
        content: '';
        width: 50px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: block;
        background: linear-gradient(to right, rgba(var(--color-brand), 0), rgba(var(--color-brand), 1));
      }

      &::before {
        transform: scaleX(-1);
        left: 0;
      }
    }
  }

  input {
    position: absolute;
    background: transparent;
    top: 26px;
    left: 0;
    width: 100%;
    height: 30px;
    appearance: none;


    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--color-background));
    }

    @media (--min-tablet) {
      display: none;
    }
  }

  .container > ul {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 0 40px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (--min-tablet) {
      flex-direction: row;
      padding-bottom: 59px;
    }

    > li {
      width: 100%;
      position: relative;

      @media (--min-tablet) {
        width: 32%;
      }

      @media (--min-desktop) {
        width: 30%;
      }
    }

    h3 {
      color: rgb(var(--color-background));
      font-family: var(--font-family-decor);
      font-weight: 400;
      font-size: calc(1.7rem * var(--font-size-modifier));
      margin: 27px 0 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid rgb(var(--color-vivid));

      @media (--min-tablet) {
        font-size: calc(2rem * var(--font-size-modifier));
        border-bottom-width: 2px;
        padding-bottom: 10px;
      }

      @media (--min-desktop) {
        font-size: calc(3rem * var(--font-size-modifier));
        margin: 57px 0 19px;
        padding-bottom: 17px;
      }
    }

    li > ul,
    li > ul li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li li {
      @media (--mobile-wide) {
        width: 50%;
        float: left;
      }
    }

    li > ul {
      max-height: 0;
      overflow: hidden;
      transition: max-height .5s;

      @media (--min-tablet) {
        max-height: none;
      }
    }

    li > input:checked + ul {
      max-height: 600px;
      transition: max-height 1.5s;
    }

    li li a {
      display: block;
      font-size: calc(1.3rem * var(--font-size-modifier));
      font-weight: 300;
      padding: 5px 9px 5px 16px;
      color: rgb(var(--color-background));
      position: relative;

      @media (--min-tablet) {
        font-size: calc(1.4rem * var(--font-size-modifier));
        padding: 6px 9px 6px 16px;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
      }

      @media (--min-desktop) {
        padding: 9px 9px 9px 16px;
        font-size: calc(1.6rem * var(--font-size-modifier));
      }

      &::before {
        content: '>';
        position: absolute;
        color: rgb(var(--color-alert));
        display: block;
        left: 0;
        font-weight: 600;
        transform: scaleX(.5);
        top: 3px;
        font-size: calc(1.5rem * var(--font-size-modifier));
        transition: transform .6s;
      }

      @media (--min-tablet) {
        &::before {
          top: 4px;
          font-size: calc(1.7rem * var(--font-size-modifier));
        }
      }

      @media (--min-desktop) {
        &::before {
          top: 6px;
          font-size: calc(2rem * var(--font-size-modifier));
        }
      }

      &:hover {
        border-bottom-color: transparent;
        
        @media (--min-tablet) {
          border-bottom-color: rgb(var(--color-background));
        }

        &::before {
          transform: scaleX(.5) translateX(5px);
        }
      }
    }
  }
}