.logo {
  font-family: var(--font-family-base);
  position: relative;
  margin: 0 0 10px;
  transform: scale3d(.65, .65, 1);
  transform-origin: 0 0;
  display: flex;
  min-height: 90px;

  @media (--min-tablet) {
    margin: 9px 0 10px;
    transform: none;
  }
  
  .label {
    display: flex;
    border: none;
    width: 100%;
    color: rgb(var(--color-medium));

    @media (--tablet) {
      max-width: 220px;
    }

    @media (--min-desktop) {
      max-width: 360px;
    }
  }

  .emblems {
    display: flex;
    @media (--min-tablet) {
      width: 180px;
    }
  }

  .coat-of-arms {
    display: block;
    width: 73px;
    height: 85px;
    margin-top: 2px;
  }

  .emblem {
    display: block;
    margin: 0 25px;
    width: 57px;
    height: 85px;
  }

  .name {
    display: flex;
    font-size: calc(2.4rem * var(--font-size-modifier));
    line-height: 1.14;
    margin-top: 2px;
    font-weight: 400;
    width: 212px;
    align-items: center;

    @media (--min-tablet) {
      font-size: calc(2.1rem * var(--font-size-modifier));
      width: 186px;
    }
  }
}