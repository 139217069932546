#acc__animations:checked ~ .body-wrapper,
#acc__animations:checked ~ .body-wrapper * {
  animation: none !important;
  transition: none !important;

  &::before,
  &::after {
    animation: none !important;
    transition: none !important;
  }

  .acc__animations__label {
    .acc__toggle--on {
      opacity: 0;
    }

    .acc__toggle--off {
      opacity: 1;
    }
  }
}