%form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  background-color: rgb(var(--color-background));
  border-radius: 0;
  padding: 5px 3px;
  outline: 0;
  color: rgba(var(--color-foreground), 0.2);
  border-bottom: 1px solid rgba(var(--color-foreground), 0.2);
  transition: border-color 0.3s;
  display: block;
  width: 100%;
  line-height: 1.5;

  &:focus {
    border-color: rgb(var(--color-vivid));
    color: rgb(var(--color-vivid));
  }

  &.error {
    border-color: rgb(var(--color-alert));
    color: rgb(var(--color-alert));

    &:focus {
      border-color: rgb(var(--color-alert));
    }
  }
}