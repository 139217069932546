.accordion {
  position: relative;

  > input {
    position: absolute;
    opacity: 1;
    pointer-events: none;
    display: block;
    width: 100%;
    height: 55px;
    top: auto;
    left: 0;
    appearance: none;
    -webkit-tap-highligt-color: trasnparent;

    &:focus {
      outline: none;

      + .slide header label {
        color: rgb(var(--color-alert));
        background: rgb(var(--color-background));
      }
    }

    &:checked {
      + .slide .content {
        max-height: 8000px;
        transition: max-height 4s;
      }

      + .slide header::after {
        transform: rotate(-45deg);
        border-color: rgb(var(--color-alert));
        top: 24px;

        @media (--min-tablet) {
          top: 27px;
        }
      }

      + .slide header label {
        color: rgb(var(--color-alert));
        background: transparent;
      }
    }
  }

  .slide {
    position: relative;

    header {

      &::after {
        border: 2px solid rgb(var(--color-lighter-alternative-2));
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        border-width: 2px 2px 0 0;
        border-radius: 2px;
        top: 19px;
        right: 21px;
        transition: transform .5s, top .4s;
        transform: rotate(135deg);
        pointer-events: none;

        @media (--min-tablet) {
          width: 10px;
          height: 10px;
          top: 21px;
        }
      }

      h2 {
        margin: 0;
        padding: 0;
      }

      label {
        font-size: calc(1.6rem * var(--font-size-modifier));
        box-shadow: 0 5px 0 0 rgb(var(--color-brand-lightest-alternative)) inset,
          0 -4px 0 0 rgb(var(--color-background)) inset;
        font-family: var(--font-family-decor);
        background: rgb(var(--color-brand-lightest));
        line-height: 1.3;
        font-weight: 400;
        padding: 10px 51px 10px 21px;
        display: flex;
        align-items: center;
        transition: color .2s, background-color .2s;
        cursor: pointer;
        min-height: 57px;
        -webkit-tap-highlight-color: transparent;

        @media (--min-desktop) {
          font-size: calc(1.8rem * var(--font-size-modifier));
        }

        &:hover,
        &:active {
          color: rgb(var(--color-brand));
          background: rgba(var(--color-brand-lightest), .5);
        }
      }
    }

    .content {
      max-height: 0;
      overflow: hidden;
      transition: max-height .3s;
      padding: 0 20px;

      @media (--min-desktop) {
        padding: 0;
      }

      > p {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
