.subpage {
  font-size: calc(1.6rem * var(--font-size-modifier));
  line-height: 1.5;

  .subpage-wrapper {
    display: flex;
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);

    @media (--mobile) {
      flex-direction: column;
    }
  }

  .aside {
    width: auto;

    @media (--mobile) {
      order: 1;
    }

    @media (--min-tablet) {
      margin: 0 20px 80px 0;
      width: 200px;
      min-width: 200px;
    }

    @media (--min-desktop) {
      margin: 0 30px 100px 0;
      width: 270px;
    }

    p:first-of-type,
    h1:first-of-type,
    h2:first-of-type,
    h3:first-of-type,
    h4:first-of-type,
    h5:first-of-type,
    h6:first-of-type, {
      margin-top: 0;
    }
  }

  main {
    @media (--min-tablet) {
      flex: 1;
    }
  }

  .content {
    &:first-child:nth-last-child(2) {
      @media (--min-tablet) {
        min-height: 80%;
      }
    }

    > *:first-child {
      margin-top: 0;
    }

    p:empty {
      display: none;
    }

    p,
    ul,
    ol {
      margin: 0;
      padding-bottom: 3rem;
    }

    a {
      color: rgb(var(--color-vivid));
      border-bottom: 1px solid rgba(255, 255, 255, 0);
      transition: border-bottom-color 0.3s, color 0.3s;

      &:hover {
        border-bottom-color: rgb(var(--color-vivid));
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    ol li {
      padding: 2px 0 2px;
    }

    ul {
      padding-left: 0;
      list-style: none;

      li {
        position: relative;
        padding-left: 15px;
        padding: 2px 0 2px 15px;

        &::before {
          content: '';
          border: 2px solid rgb(var(--color-medium));
          border-width: 2px 2px 0 0;
          width: 5px;
          height: 5px;
          display: inline-block;
          left: 0;
          top: calc(1.2rem * var(--font-size-modifier));
          position: absolute;
          transform: rotate(45deg);
          border-radius: 1px;
        }
      }
    }

    table { /* @todo: does it need to overwrite table.pcss? */
      border-collapse: collapse;
      font-size: calc(1.4rem * var(--font-size-modifier));
      width: 100%;
      margin-bottom: 3rem;

      tr:nth-of-type(even) {
        background-color: rgb(var(--color-lightest-alternative));
      }

      thead th {
        background-color: rgb(var(--color-brand-darker));
        color: rgb(var(--color-background));
        font-weight: 400;
        padding: 10px;
      }

      td,
      tbody th {
        padding: 10px;

        @media (--mobile) {
          padding: 5px 0;
        }
      }
    }
  }

  .page-meta {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: calc(1.3rem * var(--font-size-modifier));
    color: rgb(var(--color-lighter));

    .entry-tools,
    .author,
    time {
      display: inline-block;
      margin-right: 30px;
    }

    span {
      color: rgb(var(--color-foreground));
    }
  }
}
