%close {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  font: normal 0/0 serif;
  color: transparent;
  cursor: pointer;
  transition: background .2s;
  z-index: 40;

  @media (--tablet) {
    width: 50px;
    height: 50px;
  }

  @media (--min-desktop) {
    width: 60px;
    height: 60px;
  }

  &:hover,
  &:active {
    background: rgb(var(--color-background));

    &::before,
    &::after {
      background: rgb(var(--color-vivid));
    }
  }

  &::before,
  &::after {
    content: '';
    height: 2px;
    width: 18.5px;
    background: rgb(var(--color-foreground));
    position: absolute;
    top: 19px;
    left: 10px;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    transition: background-color .3s;

    @media (--tablet) {
      width: 25px;
      top: 24px;
      left: 13px;
    }

    @media (--min-desktop) {
      width: 30px;
      top: 29px;
      left: 15px;
    }
  }

  &::after {
    transform: rotate(-45deg);
  }
}