#acc__font-size__min:checked ~ .body-wrapper {
  font-size: 70%;
  --font-size-modifier: .7;
  --font-size-offset: 17px;

  .acc__font-size__label:nth-child(1) .acc__font-size__preview {
    opacity: 1;
  }
}

#acc__font-size__smaller:checked ~ .body-wrapper {
  font-size: 80%;
  --font-size-modifier: .8;
  --font-size-offset: 20%;

  .acc__font-size__label:nth-child(2) .acc__font-size__preview {
    opacity: 1;
  }
}

#acc__font-size__small:checked ~ .body-wrapper {
  font-size: 90%;
  --font-size-modifier: .9;
  --font-size-offset: 35%;

  .acc__font-size__label:nth-child(3) .acc__font-size__preview {
    opacity: 1;
  }
}

#acc__font-size__default:checked ~ .body-wrapper {
  font-size: 100%;
  --font-size-modifier: 1;
  --font-size-offset: 45%;

  .acc__font-size__label:nth-child(4) .acc__font-size__preview {
    opacity: 1;
  }
}

#acc__font-size__big:checked ~ .body-wrapper {
  font-size: 110%;
  --font-size-modifier: 1.1;
  --font-size-offset: 62%;

  .acc__font-size__label:nth-child(5) .acc__font-size__preview {
    opacity: 1;
  }
}

#acc__font-size__bigger:checked ~ .body-wrapper {
  font-size: 120%;
  --font-size-modifier: 1.2;
  --font-size-offset: 75%;

  .acc__font-size__label:nth-child(6) .acc__font-size__preview {
    opacity: 1;
  }
}

#acc__font-size__max:checked ~ .body-wrapper {
  font-size: 130%;
  --font-size-modifier: 1.3;
  --font-size-offset: calc(100% - 40px);

  .acc__font-size__label:nth-child(7) .acc__font-size__preview {
    opacity: 1;
  }
}

