.overlay-ie-info {
  display: none;

  &.visible {
    @extend %overlay-background-visible;

    background: rgba(0, 0, 0, .8);
    z-index: 600;

    .ie-info {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 601;
      background: #fff;
      width: 50%;
      height: auto;
      padding: 30px;

      p {
        font-size: 25px;
        text-align: center;
      }
    }
  }
}