@import 'accessibility-font-size-controls.pcss';
@import 'accessibility-dark-mode-controls.pcss';

.acc {
  overflow: visible;
  height: 50px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 500;

  @media (--min-tablet) {
    height: 0;
    position: relative;
  }

  .container {
    position: relative;
    padding: 0 14px;

    @media (--min-tablet) {
      padding: 0;
    }
  }

  .acc-panel__skip-to-content {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;

    &:focus,
    &:active {
      color: rgb(var(--color-background));
      background: rgb(var(--color-darker));
      left: auto;
      top: auto;
      width: 200px;
      height: auto;
      overflow: visible;
      z-index:999;
      width: 150px;
      padding: 10px 5px;
      border-radius: 0 0 5px 5px;
      text-align: center;
      border: 0;
      letter-spacing: 1px;
      font-weight: 500;
    }
  }

  .acc__panel__trigger {
    display: block;
    padding-left: 35px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: rgb(var(--color-dark));
    font-size: 1.2rem;
    transform: translateX(-100vw);
    transition: transform .1s, opacity .1s;
    cursor: pointer;

    &:focus {
      outline: 1px solid rgba(var(--color-vivid), .5);
      outline-offset: 2px;
    }

    @media (--min-tablet) {
      transform: translateX(0);
      font: normal 0/0 serfif;
      color: transparent;
      height: 30px;
      line-height: 30px;
      position: absolute;
      display: block;
      top: 5px;
      right: 83px;
      width: 65px;
      margin-right: calc(env(safe-area-inset-right) - 15px);
    }

    .nav-top__trigger:checked + .body-wrapper & {
      transform: translateX(0);
      opacity: 1;
      transition: transform .2s .1s, opacity .3s .2s;
    }

    &::before,
    &::after {
      content: 'A';
      font-size: 1.05rem;
      position: absolute;
      top: -2px;
      left: 0;
      color: rgb(var(--color-background));
      font-family: var(--font-family-base);

      @media (--min-tablet) {
        color: rgb(var(--color-foreground));
        font-size: 1.1rem;
        height: 30px;
        line-height: 30px;
      }
    }

    &::before {
      background: rgb(var(--color-dark));
      padding: 3px 17px 3px 6px;
      width: 31px;
      box-sizing: border-box;

      @media (--min-tablet) {
        background: rgb(var(--color-background));
        font-size: 1.1rem;
      }
    }

    &::after {
      font-size: 1.4rem;
      left: 15px;
      top: -1px;

      @media (--min-tablet) {
        content: 'A /';
        font-size: 1.6rem;
        left: 16px;
        background: transparent no-repeat 100% 50%;
        background-image: var(--icon-contrast);
        background-size: auto 35%;
        width: 40px;
      }
    }
  }

  .acc__panel__item {
    padding: 20px 0;
    border-bottom: 1px solid rgb(var(--color-lightest));
    display: flex;

    &:last-child {
      margin-bottom: 10px;
    }
  }

  .acc__panel__item__label {
    font-size: 1.4rem;
    color: rgb(var(--color-foreground));
    margin: 10px 0 5px;
    flex-basis: 45%;

    @media (--min-tablet) {
      flex-basis: 30%;
      font-size: 1.6rem;
      margin: 0;
    }
  }

  .acc__switch-label:focus {
    .acc__toggle--on,
    .acc__toggle--off {
      outline: 1px solid rgba(var(--color-vivid), .5);
      outline-offset: 2px;
    }
  }

  .acc__panel__item__options {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-basis: 55%;

    @media (--min-tablet) {
      flex-basis: 70%;
    }
  }

  .acc__panel__popup {
    @extend %overlay-background;

    @media (--min-tablet) {
      padding: 20px;
      border: 1px solid rgb(var(--color-light));
    }

    .container {
      width: 600px;
      max-width: 100%;
      position: relative;
      z-index: 30;
      background: rgb(var(--color-background));
      margin: 0 auto 80px;
      animation: appear .65s 1;
      padding: 0 30px;
      max-height: 100vh;
      overflow: auto;

      @media (--min-tablet) {
        margin-top: 80px;
        border: 1px solid rgb(var(--color-light));
      }
    }
  }

  .acc__panel__popup-is-visible:checked + .acc__panel__popup {
    @extend %overlay-background-visible;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
  }

  .acc__panel__popup-close {
    @extend %close;
  }

  .acc__toggle--on,
  .acc__toggle--off {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity .2s;
    white-space: nowrap;
    font-size: 1.3rem;
    height: 37px;

    &:first-child {
      opacity: 1;
    }

    &::before {
      content: '';
      background: rgb(var(--color-vivid));
      height: 20px;
      width: 30px;
      display: inline-block;
      border-radius: 12px;
      border: 1px solid rgb(var(--color-foreground));
      position: relative;
      top: 6px;
      margin-right: 10px;
    }

    &::after {
      content: '';
      width: 18px;
      height: 18px;
      position: absolute;
      top: 8px;
      left: 12px;
      background: rgb(var(--color-background));
      border-radius: 50%;
    }
  }

  .acc__toggle--off {
    opacity: 0;

    &::before {
      background: rgb(var(--color-light-alternative));
    }

    &::after {
      left: 2px;
    }
  }

  .font-size {
    position: absolute;
    top: 5px;
    right: 218px;
    display: none;

    /* @media (--min-tablet) {
      display: block;
    } */

    a {
      font: normal 0/0 serif;
      border: 0;
      color: rgb(var(--color-dark));

      &::before {
        content: 'a';
        text-transform: uppercase;
        font: normal 1.1rem Lato, Helvetica, sans-serif;
        font-weight: 500;
      }

      &:hover::before {
        color: rgb(var(--color-foreground));
      }

      + a::before {
        font-size: 1.4rem;
      }

      + a + a::before {
        font-size: 1.8rem;
      }
    }
  }

  .contrast {
    position: absolute;
    top: 8px;
    right: 178px;
    display: none;

    @media (--min-tablet) {
      display: block;
    }

    a {
      font: normal 0/0 serif;
      border: 0;

      &::before {
        content: '';
        width: 25px;
        height: 18px;
        background: var(--icon-contrast);
        display: block;
      }
    }
  }
}