@import 'normalize.pcss';
@import 'colors.pcss';
@import 'media-queries.pcss';
@import 'images.pcss';
@import 'typography.pcss';
@import 'layout.pcss';
@import 'header.pcss';
@import 'footer.pcss';
@import 'button.pcss';
@import 'hidden-checkbox.pcss';
@import 'faculties-navigation.pcss';
@import 'mixins.pcss';
@import 'extensions.pcss';
@import 'ie.pcss';
@import 'static-pages.pcss';
@import 'statement-popup.pcss';

/* accessibility */
@import 'accessibility-font-size.pcss';
@import 'accessibility-animations.pcss';
@import 'accessibility-dark-mode.pcss';
@import 'accessibility-dark-mode-high-contrast.pcss';
@import 'accessibility-high-contrast.pcss';
@import 'accessibility-colors.pcss';
@import 'accessibility-dark-mode-notification.pcss';

/* used on homepage */
@import 'home-hero.pcss';
@import 'home-news.pcss';
@import 'home-shortcuts.pcss';
@import 'carousel.pcss';

/* used for news */
@import 'news.pcss';
@import 'entries.pcss';
@import 'pagination.pcss';
@import 'entry.pcss';
@import 'gallery.pcss';

/* user for news and gallery */
@import 'gallery-full.pcss';
@import 'gallery-trigger.pcss';

/* user for gallery */
@import 'gallery-list.pcss';

/* used on all (or almost all) subpages */
@import 'content-header.pcss';
@import 'content-header-entry.pcss';
@import 'content-header-unit.pcss';
@import 'breadcrumbs.pcss';
@import 'subpage.pcss';
@import 'page-unit.pcss';
@import 'file-list.pcss';
@import 'tools.pcss';
@import 'table.pcss';
@import 'sub-navigation.pcss';
@import 'form.pcss';
@import 'modal.pcss';
@import 'tiles-nav.pcss';
@import 'progress-bar.pcss';

/* used on not yet defined subpages */
@import 'accordion.pcss';
@import 'horizon.pcss';
@import 'personel-persons.pcss';

/* used on faculties */
@import 'faculty.pcss';
@import 'return-to-default-site.pcss';

* {
  box-sizing: border-box;
}

html {
  font-size: var(--font-size);
}

body {
  margin: 0;
  padding: 0;
  font: normal 1.4rem/1.4 var(--font-family-base);
}

.body-wrapper {
  background: rgb(var(--color-background));
  color: rgb(var(--color-medium));
  top: 0;
  left: 0;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

a,
button {
  &:focus {
    outline: 1px solid rgba(var(--color-vivid), .5);
    outline-offset: 2px;
  }
}
