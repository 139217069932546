.language-panel {
  position: fixed;
  display: block;
  background: rgb(var(--color-lightest-alternative));
  height: 100px;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateX(-100vw);
  z-index: 300;
  opacity: 0;
  transition: transform .1s, opacity .1s;
  border-top: 1px solid rgb(var(--color-lighter-alternative));

  @media (--min-tablet) {
    max-width: 1220px;
    position: relative;
    margin: 0;
    height: 0;
    margin: 0 auto;
    display: block;
    left: auto;
    bottom: auto;
    background: transparent;
    transform: none;
    opacity: 1;
    border-top: 0;
  }

  @media (--over-desktop) {
    right: 0;
  }

  .nav-top__trigger:checked + .body-wrapper & {
    transform: translateX(0);
    opacity: 1;
    transition: transform .2s .1s, opacity .3s .2s;
  }

  .language-panel__container {
    list-style: none;
    padding: 0;
    margin: 0;

    @media (--min-tablet) {
      position: absolute;
      top: 7px;
      right: 0;
      padding-right: 20px;
      display: flex;
      width: 70px;
      justify-content: flex-end;
      margin-right: calc(env(safe-area-inset-right) - 15px);
    }

    &::before {
      @media (--min-tablet) {
        content: '';
        width: 1px;
        height: 30px;
        background: rgb(var(--color-lighter-alternative));
        display: block;
        position: relative;
        top: -3px;
        right: 0;
      }
    }

    .language-panel__language {
      margin-top: 10px;

      @media (--min-tablet) {
        margin-top: 0;
      }
    }

    a {
      background: transparent 50% 50% no-repeat;
      border: 0;
      display: inline-block;
      margin-left: 14px;
      background: transparent var(--flag-uk) 0 0 no-repeat;
      background-size: 30px auto;
      transition: box-shadow .2s;
      padding-left: 35px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: rgb(var(--color-dark));
      font-size: calc(1.2rem * var(--font-size-modifier));

      @media (--min-tablet) {
        font: normal 0/0 serif;
        width: 28px;
        height: 20px;
        overflow: hidden;
        background-size: cover;
        background-position: 50% 50%;
        box-shadow: 0 0 0 1px rgba(var(--color-foreground), .15);
        display: block;
      }
    }

    a.ru {
      background-image: var(--flag-ru); 
    }

    a.pl {
      background-image: var(--flag-pl); 
    }

    a:hover,
    a:active {
      box-shadow: 0 0 0 1px rgba(var(--color-vivid), 1);
    }
  }
}