.home-news {
  @media (--min-tablet) {
    padding: 0 30px;
  }

  .entries {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 15px;
    flex-wrap: wrap;

    @media (--min-desktop) {
      padding: 0;
      flex-direction: row;
    }

    .entry {
      margin: 15px 0;

      @media (--min-tablet) {
        margin: 0;
      }

      @media (--min-desktop) {
        width: 30%;
        padding: 56px 0 45px;
      }
    }
  }

  .entries header {
    color: rgb(var(--color-lighter));
    font-weight: 300;
    font-size: calc(1.2rem * var(--font-size-modifier));

    @media (--min-tablet) {
      font-size: calc(1.4rem * var(--font-size-modifier));
    }

    strong {
      font-weight: 400;
      color: rgb(var(--color-foreground));
    }
  }

  .entries h2 {
    position: relative;
    padding: 20px 0 7px;
    margin: 0;
    line-height: 1.3;

    @media (--min-tablet) {
      padding: 26px 0 7px;
    }

    &::before {
      content: "";
      width: 89px;
      height: 3px;
      background: rgb(var(--color-alert));
      position: absolute;
      top: 10px;
      left: 0;
      width: 89px;
    }

    a {
      color: rgb(var(--color-foreground));
      font-family: var(--font-family-decor);
      font-size: calc(1.6rem * var(--font-size-modifier));
      line-height: 1;

      @media (--min-tablet) {
        font-size: calc(2.1rem * var(--font-size-modifier));
      }

      &:hover {
        border-color: rgb(var(--color-foreground));
      }
    }
  }

  .image img {
    height: 80px;
    width: auto;

    @media (--min-tablet) {
      height: auto;
      width: 100%;
    }
  }

  .entries .snippet {
    margin: 10px 0;
    font-size: calc(1.3rem * var(--font-size-modifier));
    font-weight: 300;

    @media (--min-tablet) {
      font-size: calc(1.6rem * var(--font-size-modifier));;
    }

    p {
      margin-top: 0;
      margin: 0 0 22px;
    }
  }

  .more-news {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
    overflow: hidden;

    @media (--min-tablet) {
      margin-bottom: 50px;
    }

    @media (--min-desktop) {
      margin-bottom: 70px;
    }

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background: rgb(var(--color-lighter));;
      top: 50%;
      display: block;
      position: absolute;
    }

    .button {
      font-size: calc(1.2rem * var(--font-size-modifier));
      line-height: 1;
      color: rgb(var(--color-lighter));
      background: rgb(var(--color-background));
      border: 1px solid rgb(var(--color-lighter)) !important;
      position: relative;
      height: 47px;
      font-weight: 500 !important;
      padding: 16px;
      z-index: 10;

      &:hover {
        background: rgb(var(--color-vivid));
        border-color: rgb(var(--color-vivid)) !important;
        color: rgb(var(--color-background));
      }
    }
  }
}
