.default-site-return {
  width: 100%;
  height: 0;
  overflow: visible;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1220px;

  @media (--mobile) {
    display: none;
  }

  a {
    position: absolute;
    right: 115px;
    top: 10px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: calc(1.3rem * var(--font-size-modifier));
    padding-left: 5px;

    &:hover {
      text-decoration: underline;
    }

    &::before {
      content: '';
      border: 2px solid rgb(var(--color-alert));
      border-width: 0 2px 2px 0;
      width: 5px;
      height: 5px;
      display: inline-block;
      left: -5px;
      top: -1px;
      position: relative;
      transform: rotate(-45deg);
      border-radius: 1px;
    }
  }
}