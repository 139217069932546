.acc__font-size-controls {
  flex-direction: column;
  position: relative;

  @media (--min-tablet) {
    flex-direction: row;
  }

  .acc__panel__item__options {
    position: relative;
    margin-bottom: 30px;
  }

  .acc__font-size-range {
    position: absolute;
    width: calc(100% - 45px);
    z-index: 10;
    top: 62px;
    left: 20px;
    background: transparent;

    @media (--min-tablet) {
      top: 26px;
      left: calc(30% + 20px);
      width: calc(70% - 50px);
    }

    + .acc__panel__item__options {
      pointer-events: none;

      .acc__font-size__label:last-child::after,
      .acc__font-size__label:first-child::after {
        display: none;
      }
    }
  }

  .acc__font-size__label {
    width: calc(100% / 7);
    height: 30px;
    line-height: 30px;
    text-align: left;
    cursor: pointer;

    &:first-child::after {
      content: '';
      width: calc(100% - 50px);
      height: 2px;
      background: rgb(var(--color-lightest-alternative));
      border: 1px solid rgb(var(--color-lighter));
      border-radius: 2px;
      position: absolute;
      top: 50%;
      left: 18px;
      transform: translateY(-50%);
      pointer-events: none;
    }

    &:first-child::before,
    &:last-child::before {
      content: 'A';
      font-family: var(--font-family-decor);
      font-size: 1.6rem;
      color: rgb(var(--color-foreground));
      width: 30px;
      height: 30px;
      display: inline-block;
      line-height: 30px;
      transition: background-color .2s, color .2s;
    }

    &:last-child {
      text-align: right;

      &::after {
        content: '';
        width: 20px;
        height: 20px;
        border: 1px solid rgb(var(--color-foreground));
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: var(--font-size-offset);
        transform: translateY(-50%);
        background: rgb(var(--color-vivid));
        transition: left .2s, background-color .2s;
      }
    }

    &:nth-child(1)::before {
      font-size: 1rem;
    }

    &:nth-child(7)::before {
      font-size: 2.2rem;
    }
  }

  .acc__font-size__preview {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity .3s;
  }
}