@define-mixin colors-dark {
  --color-foreground: 240, 240, 240;
  --color-background: 0, 0, 0;
  --color-darker: 193, 193, 193;
  --color-dark: 200, 200, 200;
  --color-medium: 171, 171, 171;
  --color-light: 80, 94, 107;
  --color-lighter: 87, 87, 87;
  --color-lightest: 18, 18, 18;
  --color-alert: 237, 30, 37;
  --color-vivid: 30, 157, 237;
  --color-vivid-dark: 17, 127, 237;
  --color-brand: 0, 61, 118;
  --color-brand-darker: 177, 205, 240;
  --color-brand-lighter: 68, 74, 82;
  --color-brand-lightest: 35, 35, 36;

  --color-light-alternative: 52, 54, 56;
  --color-medium-alternative: 113, 113, 113;
  --color-medium-alternative-2: 113, 113, 113;
  --color-lighter-alternative: 29, 29, 29;
  --color-lighter-alternative-2: 48, 48, 48;
  --color-lighter-alternative-3: 30, 30, 30;
  --color-lightest-alternative: 7, 7, 7;
  --color-brand-darker-alternative: 113, 156, 209;
  --color-brand-lighter-alternative: 160, 171, 189;
  --color-brand-lightest-alternative: 25, 29, 38;

  --color-brand-faculty: 255, 255, 255;
}

%theme-dark {
  box-shadow: 0 0 0 100vh rgb(var(--color-background));

  .logo {
    .emblem {
      background: transparent 50% 0 no-repeat;
      background-size: auto 100%;
      background-image: var(--image-emblem-mono);
      filter: invert(100%) hue-rotate(180deg) brightness(1.75);

      img {
        opacity: 0;
      }
    }

    .name {
      color: rgb(var(--color-foreground));
    }
  }

  .logo-faculty,
  .faculties-navigation__item {
    img {
      filter: invert(100%) hue-rotate(180deg);
    }
  }

  .footer.footer .sub-footer .container .logo::before {
    filter: invert(100%) hue-rotate(180deg);
  }

  .nav-top {
    border-top-color: rgb(var(--color-lighter));
  }

  footer.footer .copyright {
    box-shadow: 0 1px 0 0 rgb(var(--color-lightest)) inset
  }

  /* navigation */
  .nav-top .nav-top__item:first-child .nav-top__item__link[href*="rekrutacja"],
  .nav-top .nav-top__item:first-child .nav-top__item__link[href*="recruitment"] {
    color: rgb(var(--color-foreground));
    background: rgb(var(--color-alert));
  }

  .nav-top .container {
    border-bottom-color: rgb(var(--color-background));
  }

  .nav-top .nav-top__item__link,
  .nav-top .nav-top__item__label,
  .nav-top .nav-top__nested-item__link,
  .nav-top .nav-top__menu {
    color: rgb(var(--color-foreground));
    background: rgb(var(--color-background));
  }

  /* home */ 
  .home-hero {
    box-shadow: none;
  }

  .carousel .carousel__slide-text,
  .carousel .carousel__slide-header,
  .home-hero .tile,
  .home-hero .tile h2 a {
    color: rgb(var(--color-foreground));
  }
  
  .home-news .more-news::before {
    background-color: rgb(var(--color-foreground));
  }

  .home-news .more-news .button {
    color: rgb(var(--color-foreground));
    border-color: rgb(var(--color-foreground)) !important;
  }

  .home-shortcuts {
    background: rgb(var(--color-lightest));

    .container {
      background: transparent;

      &::before,
      &::after {
        display: none;
      }
      
      * {
        color: rgb(var(--color-foreground));
      }

      > ul h3 {
        border-bottom-color: rgb(var(--color-dark));
      }
    }
  }

  .content-header .background-image {
    background: rgb(var(--color-lightest)) !important;
  }
  
  .content-header .cover,
  .pagination a:hover,
  .pagination .pagination-control:hover,
  .content-header h1,
  .sub-navigation.with-overview .overview a,
  .file-list .file-extension {
    color: rgb(var(--color-foreground));
  }

  .pagination a,
  .pagination .pagination-control {
    color: rgb(var(--color-dark));
  }

  .breadcrumbs a,
  .sub-navigation a {
    color: rgb(var(--color-vivid));
  }

  .file-list li {
    background-color: rgb(var(--color-lightest));
  }

  /* small-search */
  .small-search input[type="search"] {
    background: rgb(var(--color-light));
    color: rgb(var(--color-lightest));
  }

  .small-search input[type="search"]:focus {
    background: rgb(var(--color-medium));
    color: rgb(var(--color-lightest));
  }

  .small-search button::before,
  .small-search button::after {
    border-color: rgb(var(--color-lightest));
  }

  .small-search button::after {
    background-color: rgb(var(--color-lightest));
  }

  .entry-tools li a {
    filter: invert(100%) brightness(1.5);
  }

  input[type="range"] {
    &::-moz-range-track {
      background: rgb(var(--color-dark));
    }

    &::-webkit-slider-runnable-track {
      background: rgb(var(--color-dark));
    }

    &::-ms-track {
      background: rgb(var(--color-dark));
    }
  }
}