.entry-wrapper {
    margin-bottom: 30px;
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);

    @media (--min-tablet) {
      display: flex;
    }
  }

  .entry-aside {
    @media (--min-tablet) {
      min-width: 170px;
    }
  }

.entry {

  .entry-content {
    color: rgb(var(--color-medium));
    font-size: calc(1.6rem * var(--font-size-modifier));

    @media (--min-tablet) {
      border-left: 1px solid rgba(var(--color-foreground), .2);
      padding-left: 30px;
      max-width: 780px;
    }

    a {
      color: rgb(var(--color-vivid));
      text-decoration: none;
      font-weight: 300;
      border-bottom: 1px solid rgba(var(--color-background), 0);
      transition: border-bottom-color 0.3s, color 0.3s;

      &:hover {
        border-bottom-color: rgb(var(--color-vivid));
      }
    }

    p:first-of-type {
      margin-top: 0;
    }

    .lead {
      font-weight: 300;
      font-size: calc(1.8rem * var(--font-size-modifier));

      @media (--min-tablet) {
        font-size: calc(2.4rem * var(--font-size-modifier));
      }
    }

    img {
      max-width: 100%;
    }

    .video {
      margin: 1em 0;

      .player {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;

        &::before {
          display: block;
          content: '';
          padding-top: 56.25%;
        }

        iframe {
          border: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  blockquote {
    font-weight: 300;
    border-left: 1px solid rgba(var(--color-foreground), .2);
    padding-left: 30px;
    margin: 0;
  }

  .entry-tools {
    text-align: center;
  }

  .entry-text__see-also {
    margin-top: 60px;
  }
}
