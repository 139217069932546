@define-mixin colors-default {
  --color-foreground: 0, 0, 0;
  --color-background: 255, 255, 255;
  --color-darker: 44, 47, 52;
  --color-dark: 55, 55, 55;
  --color-medium: 84, 84, 84;
  --color-light: 112, 130, 148;
  --color-lighter: 175, 175, 175;
  --color-lightest: 248, 248, 248;
  --color-alert: 237, 30, 37;
  --color-vivid: 30, 157, 237;
  --color-vivid-dark: 27, 96, 165;
  --color-brand: 0, 61, 118;
  --color-brand-darker: 41, 57, 77;
  --color-brand-lighter: 138, 150, 167;
  --color-brand-lightest: 249, 250, 252;

  --color-light-alternative: 202, 209, 216;
  --color-medium-alternative: 142, 142, 142;
  --color-medium-alternative-2: 176, 183, 189;
  --color-lighter-alternative: 226, 226, 226;
  --color-lighter-alternative-2: 207, 207, 207;
  --color-lighter-alternative-3: 226, 229, 231;
  --color-lightest-alternative: 243, 243, 243;
  --color-brand-darker-alternative: 41, 57, 77;
  --color-brand-lighter-alternative: 123, 131, 145;
  --color-brand-lightest-alternative: 241, 242, 244;

  --color-brand-faculty: 0, 0, 0;
}