.static-page {
  --font-size-modifier: 1;

  header {
    border-bottom: 1px solid rgb(var(--color-lighter-alternative));
  }

  .error h2 {
    margin-top: 30px;
  }
}