.faculties-navigation.faculties-navigation.faculties-navigation.faculties-navigation {

  @media (--min-desktop) {
    left: 0;
    right: 0;
    margin: 0;
    background: rgb(var(--color-background));
    max-width: none;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    border: 0;
    box-shadow: none;
    padding-top: 0;
  }

  &::after {
    @media (--min-desktop) {
      content: '';
      width: 100vw;
      height: 100%;
      background: rgb(var(--color-background));
      position: absolute;
      bottom: 0;
      left: 50%;
      top: 0;
      border-bottom: 5px solid rgb(var(--color-alert));
      box-shadow: 0 4px 4px rgba(var(--color-foreground), .5);
      z-index: -1;
      transform: translateX(-50%);
    }
  }

  .faculties-navigation__item {
    margin: 0;
    padding: 0 20px;
    flex: 1 1 20%;

    @media (--min-desktop) {
      margin: 57px 0 80px;
      padding: 0;
    }

    @media (--short) {
      margin: 30px 0 25px;
    }
  }

  .faculties-navigation__item__link {
    font-size: calc(1.4rem * var(--font-size-modifier));
    color: rgb(var(--color-darker));
    padding: 20px 5px 20px 0;
    display: flex;
    align-items: center;
    background-size: auto 30px;
    background-position: 0 50%;
    background-repeat: no-repeat;

    &:focus {
      outline: 1px solid rgba(var(--color-vivid), .5);
      outline-offset: 5px;
    }

    @media (--min-desktop) {
      flex-direction: column;
      font-size: calc(1.6rem * var(--font-size-modifier));
      height: auto;
      font-weight: 600;
      padding: 0;
      text-align: center;
      background-size: auto 80px;
      background-position: 50% 0;
      background-repeat: no-repeat;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .navigation__item__image {
    width: auto;
    height: 30px;
    margin-right: 10px;

    @media (--min-desktop) {
      height: 80px;
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  .navigation__item__label {
    display: block;
  }

  .faculties-navigation__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .faculties-navigation__nested-item {
    margin: 0;
    padding: 0;
  }

  .faculties-navigation__nested-item__link {
    font-size: calc(1.3rem * var(--font-size-modifier));
    color: rgb(var(--color-light));
    padding: 5px;
    display: inline-block;

    @media (--min-desktop) {
      padding: 0;
    }
  }
}