:root {
  --image-coat-of-arms: url('/themes/user/site/am/images/coat-of-arms.png');
  --image-emblem: url('/themes/user/site/am/images/emblem.png');
  --icon-magnifying-glass: url('/themes/user/site/am/images/icon-magnifying-glass.png');
  --icon-contrast: url('/themes/user/site/am/images/icon-contrast.png');
  --icon-chevron: url('/themes/user/site/am/images/icon-chevron.png');
  --icon-chevron-left: url('/themes/user/site/am/images/icon-chevron-left.png');
  --flag-uk: url('/themes/user/site/am/images/flag-uk.svg');
  --flag-ru: url('/themes/user/site/am/images/flag-ru.svg');
  --flag-pl: url('/themes/user/site/am/images/flag-pl.svg');
  --image-emblem-mono: url('/themes/user/site/am/images/emblem-mono.png');
  --image-emblem-crop-mono: url('/themes/user/site/am/images/emblem-mono-crop-background.png');
  --image-print: url('/themes/user/site/am/images/icon-print.png');
  --image-pdf: url('/themes/user/site/am/images/icon-pdf.png');
  --image-bip: url('/themes/user/site/am/images/bip-logo.png');
  --image-facebook: url('/themes/user/site/am/images/icon-facebook.png');
  --image-instagram: url('/themes/user/site/am/images/icon-instagram.png');
}
