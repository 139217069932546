.gallery-trigger {
  position: relative;
  width: 100%;
  margin: 20px 0;
  text-align: center;

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background: rgb(var(--color-lighter));
    position: absolute;
    top: 50%;
    left: 0;
  }

  .gallery-trigger__button {
    cursor: pointer;
    border: 1px solid rgb(var(--color-lighter));
    background: rgb(var(--color-background));
    position: relative;
    z-index: 10;
    display: inline-block;
    height: 46px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 46px;
    padding: 0 30px;
    font-size: calc(1.2rem * var(--font-size-modifier));
    transition: background-color .3s, border-color .3s, color .25s;

    &:hover,
    &:focus {
      background: rgb(var(--color-vivid));
      border-color: rgb(var(--color-vivid));
      color: rgb(var(--color-background));
    }
  }
}