.wn_site * {
  --color-brand-faculty: #186cac;
}

.wiet_site * {
  --color-brand-faculty: #00a85c;
}

.wm_site * {
  --color-brand-faculty: #b81236;
}

.wmie_site * {
  --color-brand-faculty: #a3238e;
}

.wi_site * {
  --color-brand-faculty: #003263;
}

.faculty {
  .logo {
    display: flex;

    a {
      align-items: center;

      &:first-of-type {
        @media (--min-tablet) {
          width: 180px;
        }
      }

      &.label {
        @media (--min-tablet) {
          max-width: 350px;
        }
      }
    }

    .name {
      padding: 0;
      margin-left: 25px;
      width: 250px;
      font-size: calc(2rem * var(--font-size-modifier));

      @media (--tablet) {
        font-size: calc(1.7rem * var(--font-size-modifier));
        padding-right: 15px;
        margin-left: 15px;
      }
    }
  }

  .nav-top .container {
    @media (--min-tablet) {
      max-width: 1220px;
    }

    .nav-top__item {
      @media (--min-tablet) {
        flex: 0 0;
      }
    }
  }

  .nav-top .nav-top__item__label::after,
  .nav-top .nav-top__nested-menu,
  .faculties-navigation.faculties-navigation.faculties-navigation.faculties-navigation::after {
    border-color: var(--color-brand-faculty);
  }

  .home-news .entries h2::before,
  .nav-top .nav-top__nested-item__link::before,
  .subpage .content .tiles-nav li {
    background-color: var(--color-brand-faculty);
  }

  .content-header {
    background: var(--color-brand-faculty);

    .cover {
      background-image: linear-gradient( to right, var(--color-brand-faculty), var(--color-brand-faculty) 40%, rgba(0, 0, 0, 0) 80% );
    }
  }

  footer.footer .sub-footer .container .logo span {
    text-align: center;
    margin-bottom: 0;
  }

  .logo {
    a:first-of-type {
      @media (--tablet) {
        width: 165px;
      }
    }

    .emblem {
      @media (--tablet) {
        transform: scale(.9, .9);
        margin: 0 15px;
      }
    }

    .coat-of-arms {
      @media (--tablet) {
        transform: scale(.9, .9);
      }
    }
  }

  .acc .acc__panel__trigger {
    @media (--min-tablet) {
      right: 15px;
    }
  }

  #acc__dark-mode-invert:not(:checked) ~ .body-wrapper .acc__dark-mode-notification {
    padding-right: 100px;
  }

  #acc__dark-mode-invert:not(:checked) ~ .body-wrapper .acc__dark-mode-notification .acc__dark-mode-notification__description {
    right: 100px;
  }
}

.faculties {
  order: 3;
  display: flex;

  @media (--mobile) {
    flex-wrap: wrap;
  }

  span {
    margin: 20px;

    @media (--min-tablet) {
      margin: 0 20px;
    }
  }
}

.logo-faculty {
  display: block;
  width: 70px;
  height: 89px;
  margin-top: 2px;
  background: transparent 0 0 no-repeat;
  background-image: var(--logo-faculty);
  background-size: cover;

  @media (--tablet) {
    width: 60px;
    height: 76px;
    margin-top: 3px;
  }

  img {
    @media (--tablet) {
      width: 60px;
      height: 76px;
    }
  }
}

.faculty-hero { /* @todo: move to separate file */
  background-color: var(--color-brand-faculty);
  background-size: cover;
  padding: 30px 0;
  position: relative;

  &::after {
    content: '';
    background-color: var(--color-brand-faculty);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity:  0.8;
    z-index: 1;
  }

  .container {
    position: relative;
    z-index: 2;

    @media (--min-tablet) {
      display: flex;
      align-items: center;
      min-height: 280px;
    }
  }

  .tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (--min-tablet) {
      min-width: 50%;
      max-width: 550px;
      justify-content: flex-end;
    }

    @media (--min-desktop) {
      min-width: 550px;
    }
  }

  header {
    font-size: calc(2rem * var(--font-size-modifier));
    color: rgb(var(--color-background));

    @media (--min-tablet) {
      font-size: calc(2.5rem * var(--font-size-modifier));
      line-height: 1.2;
    }

    @media (--min-desktop) {
      font-size: calc(3rem * var(--font-size-modifier));
    }

    h2 {
      color: rgb(var(--color-background));
      font-size: calc(3rem * var(--font-size-modifier));
      line-height: 1.1;
      margin-top: 10px;
      margin-bottom: 30px;

      @media (--min-tablet) {
        font-size: calc(3.2rem * var(--font-size-modifier));
        margin-top: 10px;
        line-height: 1.2;
      }

      @media (--min-desktop) {
        font-size: calc(5rem * var(--font-size-modifier));
        margin: 0;
        line-height: 1.1;
        padding-right: 64px;
      }
    }
  }

  .tile {
    height: 130px;
    display: block;
    flex: 0 calc(50% - 10px);
    background-color: rgba(var(--color-background), .5);
    margin: 10px 0;
    padding: 0;
    transition: background-color .3s;

    @media (--min-tablet) {
      margin: 5px;
      flex-basis: calc(50% - 40px);
    }

    &:hover {
      background-color: rgba(var(--color-background), .3);
    }

    a {
      font-size: calc(1.8rem * var(--font-size-modifier));
      line-height: 1.2;
      color: rgb(var(--color-background));
      display: flex;
      height: 100%;
      padding: 15px;
      align-items: flex-end;
    }
  }
}
