.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 5px;
  pointer-events: none;
  z-index: 500;
  background: linear-gradient(to right, rgb(var(--color-vivid)), rgb(var(--color-brand)));
  transition: width .5s, opacity .5s;
}