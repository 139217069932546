%button {
  cursor: pointer;
  font-weight: 600 !important;
  box-sizing: border-box;
  text-transform: uppercase;
  border: 2px solid rgb(var(--color-background)) !important;
  font-size: calc(1.2rem * var(--font-size-modifier));
  padding: 4px 15px;
  display: inline-block;
  transition: background-color .3s;

  @media (--min-mobile-wide) {
    font-size: calc(1.3rem * var(--font-size-modifier));
    padding: 6px 18px;
  }

  @media (--over-desktop) {
    font-size: calc(1.4rem * var(--font-size-modifier));
    padding: 16px 38px;
    height: 54px;
  }

  &:hover {
    background: rgb(var(--color-vivid));
  }

  &.button--vivid {
    background-color: rgb(var(--color-vivid));
    color: rgb(var(--color-background));

    &:hover {
      background: rgb(var(--color-background));
      border-color: rgb(var(--color-vivid)) !important;
      color: rgb(var(--color-vivid));
    }
  }
}