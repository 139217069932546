.content-header {
  height: 170px;
  color: rgb(var(--color-background));
  background: rgb(var(--color-vivid-dark));
  position: relative;

  .cover {
    display: flex;
    align-items: center;
    width: 100%;
    height: 170px;
    background-image: linear-gradient(
      to right,
      rgb(var(--color-brand)),
      rgb(var(--color-brand)) 40%,
      rgba(0, 0, 0, 0) 80%
    );
    position: relative;

    @media (--min-tablet) {
      .content {
        display: flex;
        align-items: center;
      }
    }
  }

  .container {
    overflow: hidden;
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 30%;
    bottom: 0;
    right: 0;
    background: transparent 100% 50% no-repeat;
    background-size: cover;
    width: 70%;
    height: 170px;
    opacity: 0.7;
    filter: grayscale(.65);
  }

  h1 {
    font-size: calc(2rem * var(--font-size-modifier));
    color: rgb(var(--color-background));
    line-height: 1.27;
    font-weight: 400;
    margin: 10px 0;

    @media (--tablet-narrow) {
      font-size: calc(2.4rem * var(--font-size-modifier));
    }

    @media (--min-tablet-wide) {
      font-size: calc(3.5rem * var(--font-size-modifier));
      margin: 0;
    }

    span {
      display: block;
      font-size: 125%;
    }
  }
}
