@import 'mixins/colors-default.pcss';
@import 'mixins/colors-dark.pcss';

:root {
  @mixin colors-default;
}

@media (prefers-color-scheme: dark) {
  :root {
    @mixin colors-dark;

    .acc__dark-mode__label {
      .acc__toggle--on {
        opacity: 1;
      }

      .acc__toggle--off {
        opacity: 0;
      }
    }
  }
}
