.small-search {
  top: 87px;
  right: 4px;
  position: absolute;
  display: block;
  z-index: 10;
  transition: opacity .5s;

  @media (--min-tablet) {
    margin-top: 46px;
    position: relative;
    top: 0;
    right: auto;
    min-width: 210px;
  }

  @media (--min-tablet) {
    margin-top: 46px;
    position: relative;
    top: 0;
    right: auto;
    min-width: 210px;
  }

  @media (--min-tablet-wide) {
    min-width: 250px;
  }

  .nav-top__trigger:checked + .body-wrapper & {
    opacity: 0;
    pointer-events: none;

    @media (--min-tablet) {
      opacity: 1;
      pointer-events: auto;
    }
  }

  input[type="search"] {
    font-size: calc(1.4rem * var(--font-size-modifier));
    width: 140px;
    height: 34px;
    border: none;
    background: transparent;
    padding: 11px 50px 11px 8px;
    color: rgb(var(--color-darker));
    transition: width .5s, z-index .1s, opacity .2s;
    z-index: 20;
    position: relative;
    appearance: none;
    opacity: 0;

    @media (--min-tablet) {
      width: 100%;
      height: 39px;
      background: rgb(var(--color-lightest));
      border-bottom: 1px solid rgb(var(--color-lighter-alternative));
      opacity: 1;
      transition: none;
    }

    @media (--min-desktop) {
      width: 289px;
    }

    &::placeholder {
      color: rgb(var(--color-lighter));
    }

    &:focus {
      color: rgb(var(--color-darker));
      width: calc(100vw - 20px);
      background: rgb(var(--color-background));
      border: 1px solid rgb(var(--color-darker));
      outline: none;
      opacity: 1;
      z-index: 5;
      right: 5px;
      transition: width .5s, z-index 0s, opacity .5s;

      @media (--min-tablet) {
        width: 289px;
        right: auto;
      }

      + button {
        opacity: 1;
      }
    }
  }

  button {
    display: none;

    @media (--min-tablet) {
      display: block;
      background: transparent;
      border: 0;
      font: normal 0/0 serif;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      cursor: pointer;
      z-index: 30;
      opacity: .5;
      transition: opacity .3s;

      &:hover {
        opacity: 1;
      }

      &::before,
      &::after {
        content: '';
        border: 2px solid rgb(var(--color-dark));
        width: 12px;
        height: 12px;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        left: 10px;
        transition: border-color .3s, background-color .3s;
      }

      &::after {
        width: 2px;
        height: 10px;
        border-radius: 0;
        transform: rotate(-42deg);
        border: 0;
        background: rgb(var(--color-dark));
        top: 21px;
        left: 25px;
      }
    }
  }

  .close {
    display: inline-block;
    background: transparent;
    border: 0;
    position: absolute;
    top: -3px;
    right: 0;
    width: 100px;
    height: 40px;
    cursor: pointer;
    opacity: 1;
    transition: z-index 0s, opacity .3s, color .5s, width .2s;
    z-index: 5;
    text-transform: uppercase;
    text-align: right;
    font-size: calc(1.4rem * var(--font-size-modifier));
    letter-spacing: 1px;
    padding-top: 10px;
    padding-right: 40px;

    @media (--min-tablet) {
      display: none;
      opacity: .5;
    }

    &:hover {
      opacity: 1;
      background: transparent;
    }

    &:focus {
      outline: none;

      &::before,
      &::after {
        box-shadow: 0 0 0 1px rgb(var(--color-vivid));
      }
    }

    &::before,
    &::after {
      content: '';
      border: 2px solid rgb(var(--color-vivid));
      background: transparent;
      width: 12px;
      height: 12px;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: auto;
      right: 20px;
      transition: border-color .3s, background-color .3s, width .3s, height .3s, border-radius .3s, transform .3s, top .3s, left .3s;
    }

    &:hover::before {
      background: transparent;
    }

    &::after {
      width: 2px;
      height: 10px;
      border-radius: 0;
      transform: rotate(-42deg);
      border: 0;
      background: rgb(var(--color-vivid));
      top: 21px;
      right: 19px;
    }
  }

  input:focus + button + .close {
    opacity: 1;
    z-index: 30;
    transition: z-index .5s, opacity .3s, color .2s, width .6s;
    color: transparent;
    width: 0;
    overflow: hidden;

    &::before,
    &::after {
      width: 2px;
      height: 15px;
      border-radius: 0;
      transform: rotate(-45deg);
      border: 0;
      background: rgb(var(--color-vivid));
      top: 13px;
      right: 21px;
    }

    &:hover::before {
      background: rgb(var(--color-vivid));
    }

    &::after {
      transform: rotate(45deg);
    }
  }
}