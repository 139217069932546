.file-list {
  ul {
    list-style: none;
    padding: 0;
    word-break: break-word;
  }

  li {
    background-color: rgb(var(--color-brand-lightest));
    margin-bottom: 10px;
    padding: 15px 15px 15px 80px;
    position: relative;
  }

  .file-icon {
    border-radius: 3px;
    background-color: rgb(var(--color-lighter-alternative-3));
    position: absolute;
    left: 20px;
    top: 15px;
    height: 48px;
    width: 36px;

    &::before {
      content: "";
      display: block;
      border-bottom-left-radius: 3px;
      border-width: 6px;
      border-style: solid;
      border-color: rgb(var(--color-brand-lightest))
        rgb(var(--color-brand-lightest)) rgb(var(--color-medium-alternative-2))
        rgb(var(--color-medium-alternative-2));
      position: absolute;
      top: 0;
      right: 0;
      height: 0;
      width: 0;
    }

    &::after {
      content: "";
      border-width: 4px;
      border-style: solid;
      border-color: rgb(var(--color-light-alternative))
        rgb(var(--color-light-alternative))
        rgb(var(--color-lighter-alternative-3))
        rgb(var(--color-lighter-alternative-3));
      position: absolute;
      top: 12px;
      right: 0;
      height: 0;
      width: 0;
    }
  }

  .file-extension {
    border-radius: 2px;
    background-color: rgb(var(--color-brand));
    color: rgba(var(--color-background));
    display: block;
    font-size: calc(1.1rem * var(--font-size-modifier));
    line-height: 1.54;
    position: absolute;
    left: -7px;
    bottom: 7px;
    height: calc(17px * var(--font-size-modifier));
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    pointer-events: none;
  }

  .file-name {
    font-size: calc(1.6rem * var(--font-size-modifier));
    color: rgb(var(--color-vivid));
    text-decoration: none;
    font-weight: 300;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    transition: border-bottom-color 0.3s, color 0.3s;

    &:hover {
      border-bottom-color: rgb(var(--color-vivid));
    }
  }

  .file-size {
    display: block;
    color: rgb(var(--color-medium-alternative));

    abbr {
      text-decoration: none;
    }
  }
}
