.home-hero {
  position: relative;
  max-width: 100vw;
  margin: 0;
  overflow: hidden;
  box-shadow: 0 -1px 0 1px rgb(var(--color-lighter-alternative));

  @media (--min-tablet) {
    padding-top: 35%;
    height: 0;
    margin: 0 auto;
    max-width: 100%;
  }

  @media (--tablet-narrow) {
    padding-top: 40%;
  }

  @media (--min-desktop) {
    box-shadow: none;
  }

  @media (--over-desktop) {
    max-width: 1920px;
  }

  @media (--tv) {
    padding-top: 0;
    height: 720px;
  }

  .tiles {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    @media (--min-tablet) {
      position: absolute;
      flex-direction: row;
    }

    .main {
      flex-grow: 1;

      @media (--min-tablet) {
        width: 62vw;
      }
    }

    .rest {

      @media (--min-tablet) {
        width: 38%;
      }

      @media (--tablet-narrow) {
        width: 50%;
      }

      @media (--tv) {
        width: 780px;
        min-width: 780px;
      }

      .tile {
        width: 50%;
        float: left;
        padding-top: 45.5%;

        @media (--mobile-narrow) {
          width: 100%;
          margin-bottom: -1px;
        }

        @media (--tv) {
          width: 390px;
          padding-top: 355px;
        }
      }
    }
  }

  .tile {
    text-align: center;
    background-color: rgb(var(--color-light));
    display: block;
    overflow: hidden;
    color: rgb(var(--color-background));
    font-size: calc(1.3rem * var(--font-size-modifier));
    text-shadow: 0 1px 0 rgba(0, 0, 0, .5);
    font-weight: 400;
    height: 0;
    position: relative;
    border: 1px solid rgb(var(--color-background));

    @media (--mobile-wide) {
      font-size: calc(1.5rem * var(--font-size-modifier));
    }

    @media (--over-desktop) {
      font-size: calc(1.8rem * var(--font-size-modifier));
    }

    + .tile {
      background-color: rgb(var(--color-lighter));

      + .tile {
        background-color: rgb(var(--color-medium));

        + .tile {
          background-color: rgb(var(--color-light));
        }
      }
    }

    a {
      color: rgb(var(--color-background));
      border: 0;
      font-weight: inherit;
    }

    .button {
      background: rgba(var(--color-alert), .8);

      &:hover {
        background: rgb(var(--color-alert));
      }
    }

    .content {
      padding: 3px 5px 0;
      line-height: 1.33;
      font-weight: 500;
      max-width: 210px;
      margin-left: auto;
      margin-right: auto;

      @media (--mobile-wide) {
        font-size: calc(1.5rem * var(--font-size-modifier));
      }

      @media (--mobile-wider) {
        font-size: calc(1.7rem * var(--font-size-modifier));
      }

      @media (--min-mobile-wider) {
        max-width: 260px;
      }

      @media (--over-desktop) {
        font-size: calc(2rem * var(--font-size-modifier));
        max-width: 300px;
      }

      p {
        margin: 0;
        text-shadow: 0 1px 3px rgb(var(--color-foreground));
      }
    }

    h2 {
      font-family: var(--font-family-decor);
      font-size: calc(1.7rem * var(--font-size-modifier));
      font-weight: 600;
      display: inline-block;
      line-height: 1.25;
      color: rgb(var(--color-background));
      position: relative;
      max-width: 90%;
      margin: 5px;

      @media (--mobile-wide) {
        font-size: calc(2.2rem * var(--font-size-modifier));
        margin: 10px 10px 5px;
        max-width: 206px;
      }

      @media (--mobile-wider) {
        font-size: calc(2.5rem * var(--font-size-modifier));
        max-width: 210px;
      }

      @media (--tablet-narrow) {
        font-size: calc(1.4rem * var(--font-size-modifier));
      }

      @media (--tablet-wide) {
        font-size: calc(1.6rem * var(--font-size-modifier));
      }

      @media (--desktop-narrow) {
        font-size: calc(1.9rem * var(--font-size-modifier));
      }

      @media (--min-desktop-wide) {
        font-size: calc(2.2rem * var(--font-size-modifier));
        max-width: 320px;
      }

      @media (--tv) {
        font-size: calc(2.5rem * var(--font-size-modifier));
      }

      &::before {
        content: attr(data-text);
        background: rgba(var(--color-brand), 1);
        color: rgba(var(--color-brand), 0);
        display: inline;
        padding: 0;
        opacity: .55;
        transition: opacity .3s;
        box-shadow: -.4rem 1px 0 3px rgb(var(--color-brand)), .4rem 1px 0 3px rgb(var(--color-brand));
        pointer-events: none;
      }

      &:hover::before {
        opacity: .7;
      }

      a {
        transition: background-color .5s;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .tile-background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px;

      @media (--mobile-wider) {
        padding: 10px;
      }

      @media (--min-tablet-wide) {
        padding: 10px;
      }

      @media (--tv) {
        padding: 5px;
      }

      header,
      .content {
        position: relative;
        z-index: 20;
        margin-left: auto;
        margin-right: auto;

        @media (--tablet-narrow) {
          max-width: 140px;
        }
      }

      .tile-image {
        position: absolute;
        width: 101%;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;

        @media (--tablet-narrow) {
          filter: blur(2px);
          transform: translate(-50%, -50%) scale(1.1, 1.1);
          opacity: .95;
        }
      }
    }

    &:nth-child(2),
    &:nth-child(4) {
      .tile-background {
        @media (--min-tablet) {
          padding-right: calc(env(safe-area-inset-right) - 10px);
        }
      }
    }
  }
}